import * as AT from './actionTypes';
import C from '../server_communication';
import Model from '../model';

export const top_menu_select = label => ({
	type: AT.TOP_MENU_SELECT,
	payload: { label }
});

export const toggle_map_point_identify_cursor = () => ({
	type: AT.TOGGLE_MAP_POINT_IDENTIFY_CURSOR
});

export const change_identify_point_coordinates = ({identify_enabled, coordinates}) => dispatch => {
	if (!identify_enabled)
		return;
	dispatch({
		type: AT.CHANGE_IDENTIFY_POINT_COORDINATES,
		payload: { coordinates }
	});
	if (coordinates === null)
		return;
	C.GET({
    endpoint: '/dashboard_v2/maps/point',
    format: 'json',
    params: {
      lon: coordinates[0],
      lat: coordinates[1]
    }
  }).then(point_data => {
    // console.log('from actions.js : ', point_data);
    dispatch({
    	type: AT.DISPLAY_IDENTIFY_POINT_DATA,
    	payload: { point_data }
    });
  });
};

export const toggle_map_add_layer_selected = () => ({
	type: AT.TOGGLE_MAP_ADD_LAYER_SELECTED
});

export const set_region_hierarchy_data = () => dispatch => {
	C.GET({
    endpoint: '/static_v2/maps/region_hierarchy_data.json',
    format: 'json'
  }).then(data => {
  	// console.log(data);
  	dispatch({
  		type: AT.SET_REGION_HIERARCHY_DATA,
  		payload: { data }
  	})
  });
};

export const pan_to_region_extent = (value, selectedOptions) => {
	if (
		selectedOptions[selectedOptions.length - 1] && selectedOptions[selectedOptions.length - 1].extent
	) {
		return {
			type: AT.PAN_TO_REGION_EXTENT,
			payload: {
				extent: selectedOptions[selectedOptions.length - 1].extent
			}
		};
	}
};

export const add_new_layer = layer_params => dispatch => {
	C.GET({
		endpoint: '/dashboard_v2/maps/map',
		params: {
			...layer_params,
			legend_gradient_colour_stops: JSON.stringify(layer_params.legend_gradient_colour_stops)
		},
		format: 'json'
	}).then(response_obj => {
		// if (layer_params.parameter === 'accum_rain') {
		// 	console.log(response_obj);
		// 	return;
		// }
		dispatch({
			type: AT.ADD_NEW_LAYER,
			payload: {
				...layer_params,
				...response_obj
			}
		});
	});
}

export const pending_request_satisfied = pending_request_id => dispatch => {
	C.GET({
		endpoint: '/dashboard_v2/maps/map/done',
		params: { pending_request_id }
	}).then(res => {
		dispatch({
			type: AT.NEW_LAYER_ADDED,
			payload: { pending_request_id }
		});
	});
}

export const set_visibility = (checkedKeys, {checked, checkedNodes, node, event}) => {
	return {
		type: AT.SET_VISIBILITY,
		payload: { checkedKeys }
	}
}

// // This is old way
// export const change_layer_style = new_style => (dispatch, getState) => {
// 	// essentially, remove layer and add layer with new style to the same position
// 	// currently, only colour gradient is addressed
// 	console.log(new_style);
// 	let value_colour_pairs = [];
// 	for (const key in new_style.values) {
// 		value_colour_pairs.push([new_style.values[key], new_style.colours[key]]);
// 	}
// 	console.log(value_colour_pairs);
// 	const legend_gradient_colour_stops = (value_colour_pairs
// 		.sort((e1, e2) => e1[0] - e2[0])
// 		.map((vc, i) => ({
// 			value: vc[0], y_frac: 1-i/(value_colour_pairs.length-1), colour: vc[1]
// 		}))
// 	);
// 	console.log(legend_gradient_colour_stops);
// 	let layer_params_of_topmost_layer = {...([...getState().map_add_layer.layers[2].children]
// 		.filter(l => l.visible).sort((l1, l2) => (l2.zIndex - l1.zIndex))[0])};
	
// 	dispatch({
// 		type: AT.REMOVE_LAYER,
// 		payload: { title: layer_params_of_topmost_layer.title }
// 	});
// 	layer_params_of_topmost_layer.legend_gradient_colour_stops = legend_gradient_colour_stops;
// 	delete layer_params_of_topmost_layer.pending_request_id;
// 	//	The following is a copy of add_new_layer action.
// 	//	Note that it probably can't be simply called or 
// 	//	dispatched (like simple actions can be) from here,
// 	//	since thunk middleware won't get a chance.
// 	C.GET({
// 		endpoint: '/dashboard_v2/maps/map',
// 		params: {
// 			...layer_params_of_topmost_layer,
// 			legend_gradient_colour_stops: JSON.stringify(layer_params_of_topmost_layer.legend_gradient_colour_stops)
// 		},
// 		format: 'json'
// 	}).then(response_obj => {
// 		// response_obj.url += '&env=q1:0;q2:100;q3:200'; // this appendage is to be removed
// 		dispatch({
// 			type: AT.ADD_NEW_LAYER,
// 			payload: {
// 				...layer_params_of_topmost_layer,
// 				...response_obj
// 			}
// 		});
// 	});
// };

// This is new way
export const change_layer_style = new_style => (dispatch, getState) => {
	// essentially, remove layer and add layer with new style to the same position
	// currently, only colour gradient is addressed
	const legend_gradient_colour_stops = [...new_style.colour_stops];
	legend_gradient_colour_stops.sort((e1, e2) => e1.value - e2.value);
	console.log(legend_gradient_colour_stops);
	
	let layer_params_of_topmost_layer = {...([...getState().map_add_layer.layers[2].children]
		.filter(l => l.visible).sort((l1, l2) => (l2.zIndex - l1.zIndex))[0])};
	dispatch({
		type: AT.REMOVE_LAYER,
		payload: { title: layer_params_of_topmost_layer.title }
	});
	layer_params_of_topmost_layer.legend_gradient_colour_stops = legend_gradient_colour_stops;
	delete layer_params_of_topmost_layer.pending_request_id;
	//	The following is a copy of add_new_layer action.
	//	Note that it probably can't be simply called or 
	//	dispatched (like simple actions can be) from here,
	//	since thunk middleware won't get a chance.
	C.GET({
		endpoint: '/dashboard_v2/maps/map',
		params: {
			...layer_params_of_topmost_layer,
			legend_gradient_colour_stops: JSON.stringify(layer_params_of_topmost_layer.legend_gradient_colour_stops)
		},
		format: 'json'
	}).then(response_obj => {
		dispatch({
			type: AT.ADD_NEW_LAYER,
			payload: {
				...layer_params_of_topmost_layer,
				...response_obj
			}
		});
	});
};


export const add_to_selected_param_layers = (selectedKeys, {selected, selectedNodes, node, event}) => {
	console.log(selectedKeys);
	return {
		type: 'ADD_TO_SELECTED_PARAM_LAYERS',
		payload: { selectedKeys }
	};
};

// export const remove_selected_layers = ({item, key, keyPath, domEvent}) => ({
// 	type: AT.REMOVE_SELECTED_LAYERS
// });
export const remove_all_layers = () => ({
	type: AT.REMOVE_ALL_LAYERS
});

export const remove_layer = title => ({
	type: AT.REMOVE_LAYER,
	payload: { title }
});

export const set_admin_layer_visibility_due_to_zoom = checked_layer_titles => {
	return {
		type: AT.SET_ADMIN_LAYER_VISIBILITY_DUE_TO_ZOOM,
		payload: { checked_layer_titles }
	}
}

export const set_selected_modeling_region = (value, selectedOptions) => {
  console.log(selectedOptions);
  return {
  	type: AT.SET_SELECTED_MODELING_REGION,
  	payload: { value, selectedOptions }
  }
};

export const set_modeling_layer_visibility = (checkedKeys, {checked, checkedNodes, node, event}) => {
	return {
		type: AT.SET_MODELING_LAYER_VISIBILITY,
		payload: { checkedKeys }
	}
}

export const set_modeling_admin_layer_visibility_due_to_zoom = checked_layer_titles => {
	return {
		type: AT.SET_MODELING_ADMIN_LAYER_VISIBILITY_DUE_TO_ZOOM,
		payload: { checked_layer_titles }
	}
}


// // This is the RasterSource way
// export const add_modeling_region_layers = () => (dispatch, getState) => {
// 	const state = getState().modeling;
// 	const region_type = ['district', 'taluka', 'cluster', 'village'][state.selected_region.length-1];
// 	const narrowest_selected_region = state.selected_region[state.selected_region.length-1];
// 	dispatch({
// 		type: AT.PAN_TO_MODELING_REGION_EXTENT,
// 		payload: {
// 			extent: narrowest_selected_region.extent
// 		}
// 	});
// 	C.GET({
// 		endpoint: '/dashboard_v2/maps/map/region_inputs',
// 		params: {
// 			region_type,
// 			region_id: narrowest_selected_region.value,
// 			resolution: 100
// 		},
// 		format: 'json'
// 	}).then(obj_of_list_of_layer_urls => {
// 		const layer_urls = obj_of_list_of_layer_urls['list of raster_and_urls'];
// 		const layer_name_titles = {
// 			'soil_texture_id_std': 'Soil Texture',
// 			'soil_depth_category_id_std': 'Soil Depth Category',
// 			'lulc_special_type_id_std': 'LULC Type'
// 		};
// 		layer_urls.map(([name, url]) => {
// 			console.log(name);
// 			// if (name.includes('soil'))
// 			// 	return;
// 			dispatch({
// 				type: AT.ADD_MODELING_REGION_LAYER,
// 				payload: {
// 					title: layer_name_titles[name],
// 					url
// 				}
// 			})
// 		})
// 	});
	
// 	// draft.layers.push({
// 	// 	region_type,
// 	// 	region_id: narrowest_selected_region.value,
// 	// 	extent: narrowest_selected_region.extent,
// 	// 	extent_native: narrowest_selected_region.extent_native,
// 	// 	poly_id: narrowest_selected_region.poly_id
// 	// });
// };

// // This is the point-wise data way
export const set_modeling_region_static_data = () => (dispatch, getState) => {

	// dispatch(toggle_region_selection_popover_visible());
	// console.log(getState());
	const state = getState().modeling;
	// console.log(state);
	if (state.selected_region.length === 0) {
		return;
	}
	const region_type = ['district', 'taluka', 'cluster', 'village'][state.selected_region.length-1];
	const narrowest_selected_region = state.selected_region[state.selected_region.length-1];
	const region_id = narrowest_selected_region.value;
	const resolution = 100;
	dispatch({
		type: AT.PAN_TO_MODELING_REGION_EXTENT,
		payload: {
			extent: narrowest_selected_region.extent
		}
	});
	C.GET({
		endpoint: '/dashboard_v2/api/run/static?region_type='+region_type+'&region_id='+region_id+'&resolution='+resolution,
		format: 'json'
	}).then(data => {
		console.log(data);
		dispatch({
			type: AT.SET_MODELING_REGION_STATIC_DATA,
			payload: {
				identity_params: {
					region_type,
					region_id,
					resolution
				},
				data
			}
		});
		console.log('trying to get modeling-map');
		dispatch(get_modeling_map_from_points_data({
			layer_request_params: {
				resolution: 100,
				layer_name: region_type + '_' + region_id + '_' + 'depth_val',
				points_data: data.points.map(p=> ({
					x: p.x, y: p.y, data: p.depth_val
				}))
			},
			layer_group_title: `${region_type} ${region_id}`
		}));
		console.log('got modeling-map');
	});
}

export const add_model_configuration_layer_subgroup = () => ({

})

export const get_modeling_map_from_points_data = ({
	layer_request_params,
	layer_group_title,
	layer_subgroup_title
}) => dispatch => {
	console.log('getting modeling-map with parameters : ', layer_request_params);
	C.POST({
		endpoint: '/dashboard_v2/maps/data_map/',
		format: 'json',
		params: layer_request_params
	}).then(response_obj => {
		console.log(response_obj);
		dispatch({
			type: AT.ADD_LAYER_TO_MODELING_REGION_LAYER_GROUP,
			payload: {
				layer_group_title,
				layer_subgroup_title,
				layer: {
					...response_obj, 
					identity_params: layer_request_params
				}
			}
		});
	});
}

export const get_dynamic_data_and_run_model = (layer_group_title, values) => (dispatch, getState) => {
	const {region_type, region_id, crop, sowing_threshold, year, resolution} = values;
	C.GET({
		endpoint: '/dashboard_v2/api/run/dynamic?region_type='+region_type+'&region_id='+region_id+'&crop='+crop+'&sowing_threshold='+sowing_threshold+'&year='+year+'&resolution=100',
		format: 'json'
	}).then(dynamic_data => {
		console.log(dynamic_data);

		// The layer-group index (in the following) should be dynamically set to the layer-group from where the 'run' handle was used
		const layer_group = getState().modeling.layers.filter(lg => lg.title === layer_group_title)[0];
		console.log(layer_group);
		const static_data = layer_group.static_data;
		console.log(static_data.points_data_as_obj)
		dynamic_data.points.forEach(p => {
			// console.log(p.x);
			static_data.points_data_as_obj[p.x][p.y].sm1 = p.sm1;
			static_data.points_data_as_obj[p.x][p.y].sm2 = p.sm2;
		});
		let all_points = [];
		console.log(static_data.points_data_as_obj);
		for (let pt_x in static_data.points_data_as_obj) {
			for (let pt_y in static_data.points_data_as_obj[pt_x]) {
				all_points.push(static_data.points_data_as_obj[pt_x][pt_y]);
			}
		}
		const all_required_model_run_data = {
			points: all_points,
			et0: static_data.et0,
			circle_rains: dynamic_data.circle_rains,
			kc: dynamic_data.kc,
			depletion_factor: dynamic_data.depletion_factor
		};
		(new Model(all_required_model_run_data)).run();
		console.log('Model run complete');
		dispatch({
			type: AT.ADD_MODEL_CONFIGURATION_LAYER_SUBGROUP,
			payload: {
				layer_group_title: `${region_type} ${region_id}`,
				identity_params: {
					...values,
					points: all_points.map(p => p.output)
				}
			}
		});
		// Important! This is the action to fetch actual layer. Use this to add layers for any given model that has been run using any given configuration.
		// dispatch(get_modeling_map_from_points_data({
		// 	layer_request_params: values,
		// 	layer_group_title: `${region_type} ${region_id}`,
		// 	resolution: 100,
		// 	layer_name: region_type + '_' + region_id + '_' + 'aet_on_day_100',
		// 	points_data: all_points.map(p=> ({
		// 		x: p.x, y: p.y, data: p.aet[100]
		// 	})),
		// }));
	});
};

export const toggle_region_selection_popover_visible = () => ({
	type: AT.TOGGLE_REGION_SELECTION_POPOVER_VISIBLE
})

export const run_model = () => (dispatch, getState) => {
	
	// Temporary experimentation with getting raster from server by sending values
	const points_data = getState().modeling.static_data.points.map(p => ({
		x: p.x, y: p.y, data: p.depth_val
	}));
	C.POST({
		endpoint: '/dashboard_v2/maps/data_map/',
		format: 'json',
		params: {
			resolution: 100,
			points_data
		}
	}).then(data => {
		console.log(data);
		dispatch({
			type: AT.ADD_MODELING_REGION_LAYER_GROUP,
			payload: {
				title: 'Data Map',
				url: '/geoserver/pocra_v2/wms',
				params: {
					service: 'WMS',
					version: '1.1.0',
					request: 'GetMap',
					layers: 'pocra_v2:data_map_c',
					styles: 'pocra_v2:raster_gradient_for_range_3',
					srs: 'EPSG:32643',
					format: 'image/png'
				}
			}
		});
	});

	// Run the model

	// dispatch({
	// 	type: AT.RUN_MODEL
	// });
};

export const set_databases = () => dispatch => {
	C.GET({
    	endpoint: '/dashboard_v2/api/databases',
    	format: 'json'
  	}).then(data => {
  		// console.log(data.database_hierarchy);
  		dispatch({
  			type: AT.SET_DATABASES,
  			payload: {
	  			database_hierarchy: data.database_hierarchy
  			}
  		})
  	});
};

export const fetch_tabular_data = (query_params, tab_pane_key) => dispatch => {
	console.log(tab_pane_key);
	console.log(dispatch);
	if (tab_pane_key) {
		dispatch({
			type: AT.SET_DATA_TABLE_LOADING,
			payload: {
				tab_pane_key,
				loading: true
			}
		});
	}
	if (query_params.filter) {
		query_params.filter = JSON.stringify(query_params.filter);
	}
	if (query_params.sort) {
		query_params.sort = JSON.stringify(query_params.sort);
	}
	C.GET({
		endpoint: '/dashboard_v2/api/tabular_data',
		params: query_params,
		format: 'json'
	}).then(data => {
		console.log(data);
		
		data.loading = false;
		
		if (tab_pane_key === undefined) {
			data.tab_pane_key = Date.now().toString();
			dispatch({
				type: AT.ADD_DATA_TABLE,
				payload: { data	}
			});
		} else {
			data.tab_pane_key = tab_pane_key;
			dispatch({
				type: AT.UPDATE_DATA_TABLE,
				payload: { data	}
			});
		}
	});
};

export const map_tabular_data = (query_params, tab_pane_key) => dispatch => {
	if (query_params.filter) {
		query_params.filter = JSON.stringify(query_params.filter);
	}
	if (query_params.legend_gradient_colour_stops) {
		query_params.legend_gradient_colour_stops = JSON.stringify(query_params.legend_gradient_colour_stops);
	}
	console.log(dispatch);
	C.GET({
		endpoint: '/dashboard_v2/maps/map/tabular_data',
		params: query_params,
		format: 'json'
	}).then(response_obj => {
		console.log(response_obj);
		if (query_params.filter) {
			query_params.filter = JSON.parse(query_params.filter);
		}
		if (query_params.legend_gradient_colour_stops) {
			query_params.legend_gradient_colour_stops = JSON.parse(query_params.legend_gradient_colour_stops);
		}
		dispatch({
			type: AT.TOP_MENU_SELECT,
			payload: { label: 'Maps'}
		});
		dispatch({
			type: AT.ADD_NEW_LAYER,
			payload: {
				...query_params,
				...response_obj
			}
		});
	});
};

export const change_data_tab_pane = targetKey => ({
	type: AT.CHANGE_DATA_TAB_PANE,
	payload: { targetKey }
})

export const remove_data_table = targetKey => ({
	type: AT.REMOVE_DATA_TABLE,
	payload: { targetKey }
})

export const handle_table_change = (pagination, filters, sorter) => {
	console.log(pagination, filters, sorter);
};