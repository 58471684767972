import { combineReducers } from 'redux';
import top_menu from './top_menu';
import region_hierarchy_data from './region_hierarchy_data';
import map_pan_region_extent from './map_pan_region_extent';
import map_identify_point from './map_identify_point';
import map_add_layer from './map_add_layer';
import modeling from './modeling';
import databases from './databases';


export default combineReducers({
	top_menu,
	region_hierarchy_data,
	map_pan_region_extent,
	map_identify_point,
	map_add_layer,
	modeling,
	databases,
});