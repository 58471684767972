const region_hierarchy_data = (state = null, action) => {
	// console.log(action);
	switch(action.type) {
		case 'SET_REGION_HIERARCHY_DATA':
			return action.payload.data;
		default:
			return state;
	}
}

export default region_hierarchy_data;