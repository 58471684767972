import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	Popover, Icon, Tabs,
	Select, Radio, Dropdown,
	Divider, Button, Menu,
	Collapse, Row, Col,
} from 'antd';

// import { getDatabasesPageState } from '../redux/selectors';
// import {
// 	fetch_tabular_data,
// 	change_data_tab_pane,
// 	remove_data_table,
// 	handle_table_change
// } from '../redux/actions';

import * as constants from '../constants';


class InputSuggest extends Component {

	constructor(props) {
		super(props);
		this.textareaRef = React.createRef();
	}

	render() {
		return (
			<div className="dropdown">
				<textarea
					ref={this.textareaRef}
					className="textarea-suggest"
					value={this.props.inputString} //using value instead of defaultValue disables the user from modifying text using keyboard
					rows="3"
				/>
				<div className="dropdown-content">
					{(this.props.enableApply) ? (
						<React.Fragment>
						<div
							key="Apply"
							className="input-suggestion"
							style={{color: 'blue'}}
							onClick={() => this.props.onSuggestionSelect('Apply')}
						>
							Apply
						</div>
						{/*<Divider />*/}
						</React.Fragment>
					) : null}
					{(this.props.inputString) ? (
						<React.Fragment>
						<div
							key="Clear"
							className="input-suggestion"
							style={{color: 'red'}}
							onClick={() => this.props.onSuggestionSelect('Clear')}
						>
							Clear
						</div>
						{/*<Divider />*/}
						</React.Fragment>
					) : null}
					{(this.props.enableInputInDropdown) ? (
						<div>
							Enter Value : <input onChange={e => {this.props.onSuggestionSelect(e.target.value)}} />
						</div>
					) : null}
					{(this.props.suggestions) ? (
						<div style={{height: 100, overflowY: 'scroll'}}>
							{this.props.suggestions.map(s => (
							<div
								key={s}
								className="input-suggestion"
								onClick={() => this.props.onSuggestionSelect(s)}
							>
								{s}
							</div>
							))}
						</div>
					) : null}
				</div>
			</div>
		);
	}
}


class DataToolsWidget extends Component {

	constructor(props) {
		super(props);
		this.column_names = this.props.data_table.columns.map(c => c.title);
		this.state = {
			filter: JSON.parse(JSON.stringify(this.props.data_table.filter)), // this is to get a deep-copy(for reason check the sort property (on the next line)); since {...obj} or [...arr] do not give deep copy
			sort: JSON.parse(JSON.stringify(this.props.data_table.sort)), // react does not deep copy; so changes to (for example) this.state.sort[i].direction also change this.props.data_table.sort[i].direction
			filter_changed: false,
			sort_changed: false,
			filter_in_contemplation: [], // the following comment is probably obsolete: something like [column[0].title, operator for columns[0].display_type, default value for columns[0].display_type]
			sort_in_contemplation: [],
			filter_suggestions: this.column_names,
			sort_suggestions: [], // this.column_names, Commenting out <this.column_names> since <sort_input_string> (below) already contains all columns
			filter_input_string: '',
			sort_input_string: this.props.data_table.sort.map(({title, direction}) => title + ' ' + direction).join(', then resolve ties by '),
			enable_input_in_dropdown: false,
			map_rows: 'Currently Filtered',
			map_colouring_column: 'NIL',
		}
	}

	is_sort_same_as_original = new_sort => {
		const original_sort = this.props.data_table.sort;
		if (original_sort.length !== new_sort.length) {
			return false;
		}
		for (let i = 0; i < original_sort.length; i++) {
			if (original_sort[i].title !== new_sort[i].title || original_sort[i].direction !== new_sort[i].direction) {
				return false;
			}
		}
		return true;
	}

	handle_sort_suggestion_selection = (suggestion) => {
		console.log(suggestion);
		let new_sort_suggestions, new_sort_input_string, new_sort;
		if (suggestion === 'Apply') {
			this.props.fetch_tabular_data({
				table: this.props.data_table.title,
				filter: this.state.filter,
				sort: this.state.sort,
				page: 1, page_size: constants.DATA_TABLE_PAGE_SIZE
			}, this.props.data_table.tab_pane_key);
			this.setState({
				filter_changed: false,
				sort_changed: false
			});
		} else if (suggestion === 'Clear') {
			this.setState({
				sort: [],
				sort_suggestions: this.column_names,
				sort_input_string: '',
				sort_changed: this.props.data_table.sort.length !== 0
			});
		} else if (this.column_names.indexOf(suggestion) !== -1) {
			new_sort = [...this.state.sort, {title: suggestion, direction: undefined}];
			this.setState({
				sort_suggestions: ['Ascending', 'Descending'],
				sort: new_sort,
				sort_input_string: this.state.sort_input_string + ' ' + suggestion
			});
		} else if (['Ascending', 'Descending'].indexOf(suggestion) !== -1) {
			new_sort = [...this.state.sort];
			new_sort[new_sort.length - 1].direction = suggestion;
			this.setState({
				sort_suggestions: ['then resolve ties by'],
				sort: new_sort,
				sort_input_string: this.state.sort_input_string + ' ' + suggestion + ' order',
				sort_changed: !this.is_sort_same_as_original(new_sort)
			});
		} else if (suggestion === 'then resolve ties by') {
			const sort_columns = this.state.sort.map(s => s.title);
			new_sort_suggestions = this.column_names.filter(c => sort_columns.indexOf(c) === -1);
			this.setState({
				sort_suggestions: new_sort_suggestions,
				sort_input_string: this.state.sort_input_string + ', ' + suggestion
			});
		}
		// else if (suggestion === 'Done') {
		// 	this.setState({
		// 		sort_suggestions: null,
		// 		sort_changed: !this.is_sort_same_as_original(this.state.sort_in_contemplation)
		// 	});
		// }
	}

	handle_filter_suggestion_selection = (suggestion) => {
		console.log(suggestion);
		let all_operators = ['LIKE', 'NOT LIKE', 'equal to', 'not equal to',
			'less than', 'not less than', 'greater than', 'not greater than'];
		if (suggestion === 'Apply') {
			this.props.fetch_tabular_data({
				table: this.props.data_table.title,
				filter: this.state.filter,
				sort: this.state.sort,
				page: 1, page_size: constants.DATA_TABLE_PAGE_SIZE
			}, this.props.data_table.tab_pane_key);
			this.setState({
				filter_changed: false,
				sort_changed: false,
				enable_input_in_dropdown: false
			});
		} else if (suggestion === 'Clear') {
			this.setState({
				filter: {},
				filter_suggestions: this.column_names,
				filter_input_string: '',
				filter_changed: this.props.data_table.filter !== null,
				enable_input_in_dropdown: false,
			});
		} else if (this.column_names.indexOf(suggestion) !== -1) {
			let column_type = this.props.data_table.columns.filter(col => col.title === suggestion)[0].data_type;
			let operators = ((column_type === 'string') ? ['LIKE', 'NOT LIKE'] : ['equal to',
				'not equal to', 'less than', 'not less than', 'greater than', 'not greater than'
			]);
			this.setState({
				filter_suggestions: operators,
				filter: {column_name: suggestion},
				filter_input_string: suggestion
			});
		} else if (all_operators.indexOf(suggestion) !== -1) {
			this.setState({
				filter_suggestions: [],
				filter: {...this.state.filter, operator: suggestion, value: ''},
				filter_input_string: this.state.filter.column_name + ' ' + suggestion,
				filter_changed: true,
				enable_input_in_dropdown: true
			});
		} else if (this.state.enable_input_in_dropdown) {
			this.setState({
				filter: {...this.state.filter, value: suggestion},
				filter_input_string: this.state.filter.column_name + ' ' + this.state.filter.operator + ' ' + suggestion,
				enable_input_in_dropdown: true
			});
		}
	}

	
	render() {
		let current_filter_string = this.props.data_table.filter ? (
			this.props.data_table.filter.column_name + ' ' + this.props.data_table.filter.operator + ' ' + this.props.data_table.filter.value
		) : '';

		return (
		<Tabs
			defaultActiveKey="Filter & Sort"
			onChange={null}
			style={{minWidth: 600, maxWidth: 600}}
		>
			<Tabs.TabPane tab="Filter & Sort" key="Filter & Sort" style={{minHeight: 400, maxHeight: 400, overflowY: 'scroll'}}>
				<div style={{overflow: 'visible'}}>
					<p style={{fontWeight: 'bold'}}>Sort By : </p>
					<InputSuggest
						inputString={this.state.sort_input_string}
						suggestions={this.state.sort_suggestions}
						onSuggestionSelect={this.handle_sort_suggestion_selection}
						enableApply={
							(this.state.sort.length === 0 || this.state.sort_suggestions[0] === 'then resolve ties by')
							&& this.state.sort_changed
						}
					/>
				</div>
				<div style={{overflow: 'visible'}}>
					<p style={{fontWeight: 'bold'}}>Filter : </p>
					<InputSuggest
						inputString={this.state.filter_input_string}
						suggestions={this.state.filter_suggestions}
						onSuggestionSelect={this.handle_filter_suggestion_selection}
						enableApply={this.state.filter_changed}
						enableInputInDropdown={this.state.enable_input_in_dropdown}
					/>
				</div>
				<div>
					<Row>
						<Col span={12}>
							<p style={{fontSize: '1.1em', fontWeight: 'bold'}}>Current</p>
							<p>
								<span style={{fontWeight: 'bold'}}>Filter : </span>
								<br />
								{this.props.data_table.filter ? current_filter_string : 'NIL'}
							</p>
							<p style={{fontWeight: 'bold'}}>Sort Priority :</p>
							<ol>
							{this.props.data_table.sort
								? this.props.data_table.sort.map(s => <li key={s.title}>{`${s.direction} order of ${s.title}`}</li>)
								: 'NIL'
							}
							</ol>
						</Col>
						<Col span={12}>
							<p style={{fontSize: '1.1em', fontWeight: 'bold'}}>
								{
									'Change Status : ' + (
										(this.state.filter_changed || this.state.sort_changed) ?
										'Changed' : 'No New Changes Yet'
									)
								}
							</p>
							<p>
								<span style={{fontWeight: 'bold'}}>Filter : </span>
								<br />
								{this.state.filter ? this.state.filter_input_string : 'NIL'}
							</p>
							<p style={{fontWeight: 'bold'}}>Sort Priority :</p>
							<ol>
							{this.state.sort ?
								this.state.sort.map(s => <li key={s.title}>{`${s.direction} order of ${s.title}`}</li>) : 'NIL'
							}
							</ol>
						</Col>
					</Row>
				</div>
			</Tabs.TabPane>
			{this.props.data_table.title === 'FFS Current Year Sowing Data' ? (
				<Tabs.TabPane tab="Mapping" key="Mapping">
					<div style={{textAlign: "center"}}>
						<Radio.Group
							defaultValue="Currently Filtered"
							style={{marginTop: 5}}
							options={[
								{label: 'Entire Table', value: 'Entire Table'},
								{label: 'Currently Filtered', value: 'Currently Filtered'},
								//{label: "Current Page", value:"Current Page"}
							]}
							onChange={event => {this.setState({map_rows: event.target.value});}}
						/>
					</div>
					<div style={{textAlign: 'center', marginTop: 10}}>
						Colour By : 
						<Select
							style={{width: 200}}
							defaultValue={this.state.map_colouring_column}
							onChange={(val, opt) => {this.setState({map_colouring_column: val}); console.log(this.state);}}
						>
							{this.props.data_table.columns.filter(col => col.colour_by).map(
								col => <Select.Option value={col.title}>{col.title}</Select.Option>
							)}
						</Select>
					</div>
					<div style={{textAlign: 'center', marginTop: 10}}>
						{/*<Button
							style={{marginRight: 5}}
							onClick={null}
						>
							Download Data
						</Button>*/}
						<Button
							style={{marginLeft: 5}}
							onClick={e => {
								e.stopPropagation();
								console.log(this.props);
								this.props.map_tabular_data({
									table: this.props.data_table.title,
									title: this.props.data_table.title + (this.state.map_colouring_column !== 'NIL' ? '; Coloured by: ' + this.state.map_colouring_column : '') + (current_filter_string ? ('; Filtered by: ' + current_filter_string) : ''),
									filter: (this.state.map_rows === 'Currently Filtered' ?
										this.state.filter : null),
									colour_by: this.state.map_colouring_column,
									legend_gradient_colour_stops: (this.state.map_colouring_column !== 'NIL' ?
										this.props.data_table.columns.filter(
											c => c.title === this.state.map_colouring_column
										)[0].legend_gradient_colour_stops : null
									),
									measurement_unit: (this.state.map_colouring_column !== 'NIL' ?
										this.props.data_table.columns.filter(
											c => c.title === this.state.map_colouring_column
										)[0].measurement_unit : null
									),
									// page: 1, page_size: constants.DATA_TABLE_PAGE_SIZE
								}, this.props.data_table.tab_pane_key);
							}}
						>
							Map the Data
						</Button>
					</div>
				</Tabs.TabPane>
			) : null}
		</Tabs>
		);
	}
}

export default DataToolsWidget;