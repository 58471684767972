import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	Popover, Tooltip, Icon, Cascader, Select, Radio,
	Tabs, Tree, Divider, Button, Dropdown, Menu,
	Table, Collapse, Row, Col, Empty
} from 'antd';

import { getDatabasesPageState } from '../redux/selectors';
import {
	fetch_tabular_data,
	map_tabular_data,
	change_data_tab_pane,
	remove_data_table,
	handle_table_change
} from '../redux/actions';

import DataToolsWidget from './DataToolsWidgetComponent';

import * as constants from '../constants';


class DatabasesPage extends Component {

	on_tabs_edit = (targetKey, action) => {
		if (action === 'remove') {
			this.props.remove_data_table(targetKey);
		}
	}

	render() {
		// console.log(this.props);

		const { 
			database_hierarchy, selected_database, data_tables, active_tab_pane_key,
			fetch_tabular_data, map_tabular_data, change_data_tab_pane
		} = this.props;
		// console.log(database_hierarchy);

		// const {
		// 	region_hierarchy_data,
		// 	map_identify_point,
		// 	map_add_layer,
		// 	toggle_map_point_identify_cursor,
		// 	toggle_map_add_layer_selected,
		// 	pan_to_region_extent
		// } = this.props;
		// console.log('from MapsPage : ', this.props)

		// const region_pan_cascader = (
		// 	<Cascader
		// 		options={region_hierarchy_data}
		// 		changeOnSelect={true}
		// 		onChange={pan_to_region_extent}
		// 		style={{width: 400}}
		// 	/>
		// );

		const build_database_tree = data_obj => data_obj.map(d => {
			if (d.children) {
				return (
					<Tree.TreeNode key={d.value} title={
						<Popover
							content={
								<p>{d.description}</p>
							}
							placement="right"
							trigger="click"
							overlayStyle={{maxWidth: 300, maxHeight: 150, overflow: 'scroll'}}
						>
							<span style={{fontWeight: 'bold'}}>{ d.label }</span>
						</Popover>
					}>
						{build_database_tree(d.children)}
					</Tree.TreeNode>
				);
			}
			return (
				<Tree.TreeNode key={d.value} title={
					<Popover
						content={
							<React.Fragment>
								<p>{d.description}</p>
								<Divider />
								<div style={{textAlign: 'center'}}>
									<Button
										type="primary"
										onClick={e => fetch_tabular_data({
											table: d.value, page: 1, page_size: constants.DATA_TABLE_PAGE_SIZE
										})}
									>
										Get Data
									</Button>
								</div>
							</React.Fragment>
						}
						placement="right"
						trigger="click"
						overlayStyle={{maxWidth: 300, maxHeight: 150, overflow: 'scroll'}}
					>
						{ d.label }
					</Popover>
				} />
			);
		});

		// const databases_tree = this.props.databases.database_hierarchy ? (
		// 	<div style={{height: 400, width: 300, overflow: 'scroll'}}>
		// 		<Tree blockNode>
		// 			{
		// 				this.props.databases.database_hierarchy.map(d => (
		// 					d.children ? (
		// 					<Tree.TreeNode key={d.value} title={
		// 						<Popover
		// 							content={
		// 								<p>Description</p>
		// 							}
		// 							placement="right"
		// 							trigger="click"
		// 						>
		// 							<span style={{fontWeight: 'bold'}}>{ 'Database: ' + d.label }</span>
		// 						</Popover>
		// 					}>
		// 						{d.tables.map(t => <Tree.TreeNode key={t.value} title={
		// 							<Popover
		// 								content={
		// 									<React.Fragment>
		// 										<p>Description</p>
		// 										<Divider />
		// 										<Button type="primary"> Get Data </Button>
		// 									</React.Fragment>
		// 								}
		// 								placement="right"
		// 								trigger="click"
		// 							>
		// 								{'Data Table: ' + t.label}
		// 							</Popover>
		// 						} />)}
		// 					</Tree.TreeNode>
		// 				))
		// 				// this.build_databases_subtree(this.props.databases.databases)
		// 			}
		// 		</Tree>
		// 	</div>
		// ) : null;

		const build_data_table_tab = data_table => (
			<Tabs.TabPane
				tab={
					<React.Fragment>
						<Popover
							content={
								<DataToolsWidget
									data_table={data_table}
									fetch_tabular_data={fetch_tabular_data}
									map_tabular_data={map_tabular_data}
								/>
							}
							placement="bottomRight"
						>
							<span style={{paddingRight: 5, color: 'blue'}}>
								<Icon type="tool" />
							</span>
						</Popover>
						<span>{ data_table.title }</span>
					</React.Fragment>
				}
				key={data_table.tab_pane_key}
				forceRender={true}
				closable={true}
				style={{maxWidth: '100%', maxHeight: '100%'}}
			>
				<Table
					dataSource={data_table.dataSource}
					columns={data_table.columns}
					size="small"
					pagination={{
						current: data_table.page,
						defaultPageSize: 100,
						total: data_table.total,
						onChange: (page, pageSize) => fetch_tabular_data({
							table: data_table.title,
							filter: data_table.filter, sort: data_table.sort,
							page: page, page_size: constants.DATA_TABLE_PAGE_SIZE
						}, data_table.tab_pane_key)
					}}
					onChange={handle_table_change}
					loading={data_table.loading}

					scroll={{x: 1300, y: window.innerHeight - 250}}
				/>
			</Tabs.TabPane>
		);
		// console.log(window.innerHeight - 200);

		return (
			<React.Fragment>
				<div id="databases-left-sidebar-grid">
					<Popover
						content={
							<div style={{height: 400, width: 300, overflow: 'scroll'}}>
								<Tree
									blockNode
									defaultExpandAll
								>
									{database_hierarchy ? build_database_tree(database_hierarchy) : null}
								</Tree>
							</div>
						}
						placement="right"
						title="Data List/Hierarchy"
						trigger="click"
					>
						<Tooltip placement="right" title="Browse data hierarchy" trigger="hover">
							<button style={{height: 100}}><Icon type="database" /></button>
						</Tooltip>
					</Popover>
					{/*
					<Popover
						content={filter_widget}
						placement="right"
						title="Filter"
						trigger="click"
					>
						<button
							onClick={null}
						>
							<Icon type="filter" />
						</button>
					</Popover>
					<Popover
						content={sort_widget}
						placement="right"
						title="Sort"
						trigger="click"
					>
						<button
							onClick={null}
						>
							&#8645;
						</button>
					</Popover>
					
					<Tooltip placement="right" title="Download">
						<button><Icon type="download" /></button>
					</Tooltip>
					<Popover content={null} placement="rightTop" title="Get Map">
						<button><Icon type="compass" /></button>
					</Popover>
					*/}
				</div>
				<div id="databases-content" >
					{(data_tables.length > 0) ? (
						<Tabs
							type="editable-card"
							activeKey={active_tab_pane_key}
							hideAdd={true}
							onChange={change_data_tab_pane}
							onEdit={this.on_tabs_edit}
							style={{maxWidth: '100%', maxHeight: '100%'}}
							animated={true}
						>
						{data_tables.map(build_data_table_tab)}
						</Tabs>
						) : (
							<Empty
								description="No data requested yet. Use the blue button on the left panel to browse databases and request data."
							/>
						)
					}
				</div>
				{/*
				<div id="databases-right-sidebar-grid">
				
				</div>
				*/}
			</React.Fragment>
		);
	}
}

const mapStateToProps = getDatabasesPageState;
const mapDispatchToProps = {
	fetch_tabular_data,
	map_tabular_data,
	change_data_tab_pane,
	remove_data_table,
	handle_table_change
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DatabasesPage);