const top_menu = (state = 'Maps', action) => {
	// console.log(action);
	switch(action.type) {
		case 'TOP_MENU_SELECT':
			return action.payload.label;
		default:
			return state;
	}
}

export default top_menu;