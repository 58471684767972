import produce from 'immer';


const initial_state = {
	database_hierarchy: null,
	selected_database: null,
	data_tables: [],
	active_tab_pane_key: null,
};

const databases = (state=initial_state, action) => produce(state, draft => {
	let data_table_idx;
	switch(action.type) {
		case 'SET_DATABASES':
			draft.database_hierarchy = action.payload.database_hierarchy;
			break;
		case 'SET_SELECTED_DATABASE':
			draft.selected_database = action.payload.database;
			break;
		case 'ADD_DATA_TABLE':
			draft.data_tables.push(action.payload.data);
			draft.active_tab_pane_key = action.payload.data.tab_pane_key;
			break;
		case 'UPDATE_DATA_TABLE':
			data_table_idx = draft.data_tables
				.map(dt => dt.tab_pane_key).indexOf(action.payload.data.tab_pane_key);
			draft.data_tables[data_table_idx] = action.payload.data;
			break;
		case 'SET_DATA_TABLE_LOADING':
			data_table_idx = draft.data_tables
				.map(dt => dt.tab_pane_key).indexOf(action.payload.tab_pane_key);
			draft.data_tables[data_table_idx].loading = action.payload.loading;
			break;
		case 'CHANGE_DATA_TAB_PANE':
			draft.active_tab_pane_key = action.payload.targetKey;
			break;
		case 'REMOVE_DATA_TABLE':
			if (draft.data_tables[0].tab_pane_key === action.payload.targetKey) {
				draft.active_tab_pane_key = draft.data_tables[1] ? draft.data_tables[1].tab_pane_key : null;
				draft.data_tables.shift();
			} else {
				for (let i = 1; i < draft.data_tables.length; i++) {
					if (draft.data_tables[i].tab_pane_key === action.payload.targetKey) {
						draft.active_tab_pane_key = draft.data_tables[i-1].tab_pane_key;
						draft.data_tables.splice(i, 1);
						break;
					}
				}
			}
			break;
	}
});

export default databases;