import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';

import root_reducer from './reducers';


// For setup with middleware and/or enhancers
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default createStore(
	root_reducer,
	/* preloadedState, */
	composeEnhancers(applyMiddleware(thunkMiddleware))
);

//// For basic setup
// export default createStore(
// 	root_reducer,
// 	/*preloadedState,*/
// 	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );