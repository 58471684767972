class Model {
	constructor(data) {
		this.data = data;
	}

	run() {
		let sowing_threshold = 30, circles = [];
		this.circle_rains = {};
		if (this.data.circle_rains.length == 0) {
			console.log('Rain not available');
			return;
		} else {
			this.data.circle_rains.forEach(cr => {
				circles.push(cr[0]);
				this.circle_rains[cr[0]] = cr[1];
			});
		}
		console.log(this.circle_rains);
		// return;
		console.log('from Model.run');
		console.log(this.data);
		this.calculate_pets(sowing_threshold);
		console.log(this.pets);
		// return;
		this.points = this.data.points.filter(p => circles.includes(p.circle));
		this.points.forEach(p => {
			p.accum_sm = 0; p.sm1_fraction = p.wp; p.layer2_moisture = p.wp;
			p.output = {
				x: p.x,	y: p.y,
				sm: [], pri_runoff: [], infil: [], aet: [], sec_runoff: [], gw_rech: []
			};
			// p.sm = []; p.pri_runoff = []; p.infil = []; p.aet = []; p.sec_runoff = []; p.gw_rech = [];
		});
		console.log('beginning the run');
		console.time('model-run');
		for (let i = 0; i < 365; i++) {
			// console.log(i);
			this.run_for_day(i);
		}
		console.timeEnd('model-run');
		console.log(this.points);
	}

	calculate_pets(sowing_threshold) {
		let total_rain;
		let sowing_date_offset;
		this.pets = {};
		for (const circle in this.circle_rains) {
			total_rain = 0;
			sowing_date_offset = null;
			this.pets[circle] = [];
			for(let i = 0; i < 365; i++) {
				total_rain += this.circle_rains[circle][i];
				// console.log(total_rain);
				if (total_rain > sowing_threshold) {
					sowing_date_offset = i;
					break;
				} else {
					this.pets[circle].push(0);
				}
			}
			for(let i = sowing_date_offset; i < 365; i++) {
				this.pets[circle].push(this.data.et0[i] * this.data.kc[i-sowing_date_offset]);
			}
		}
	}



	run_for_day(day) {
		this.points.forEach(p => {
			let sw, s_swat, ia_swat, ks, sm1_before, r_to_second_layer, sm2_before;
			// pri_runoff
			sw = p.sm_at_wp + p.accum_sm - p.wp * p.depth_val;
			s_swat = p.smax*(1 - sw/(sw + Math.exp(p.w1 - p.w2*sw)));
			ia_swat = 0.2 * s_swat;
			// console.log(p.circle);
			// p.pri_runoff.push((this.circle_rains[p.circle][day] <= ia_swat) ? 0 : ((this.circle_rains[p.circle][day]-ia_swat)**2) / (this.circle_rains[p.circle][day] + 0.8*s_swat));
			p.output.pri_runoff.push((this.circle_rains[p.circle][day] <= ia_swat) ? 0 : ((this.circle_rains[p.circle][day]-ia_swat)**2) / (this.circle_rains[p.circle][day] + 0.8*s_swat));

			// infil
			// p.infil.push(this.circle_rains[p.circle][day] - p.pri_runoff[day]);
			p.output.infil.push(this.circle_rains[p.circle][day] - p.output.pri_runoff[day]);

			// aet
			if (p.sm1_fraction < p.wp) {
				ks = 0;
			}	else if (p.sm1_fraction > (p.fc*(1- this.data.depletion_factor) + this.data.depletion_factor*p.wp)) {
				ks = 1;
			}	else {
				ks = (p.sm1_fraction - p.wp)/(p.fc - p.wp) /(1- this.data.depletion_factor);
			}
			// p.aet.push(ks * this.pets[p.circle][day]);
			p.output.aet.push(ks * this.pets[p.circle][day]);

			// sm1_before R_to_second_layer and sm2_before gw_rech
			sm1_before = (p.sm1_fraction*p.sm1 + (p.output.infil[day]-p.output.aet[day])/1000) / p.sm1;
			if (sm1_before < p.fc) {
				r_to_second_layer = 0;
			} else if (p.layer2_moisture < p.sat) {
				r_to_second_layer = Math.min((p.sat - p.layer2_moisture)*p.sm2*1000, (sm1_before - p.fc)*p.sm1*1000*p.daily_perc_factor);
			} else {
				r_to_second_layer = 0;
			}
			sm2_before = ((p.layer2_moisture*p.sm2*1000 + r_to_second_layer) / p.sm2) / 1000;
			
			// sec_runoff
			if (((sm1_before*p.sm1 - r_to_second_layer/1000)/p.sm1) <= p.sat) {
				// p.sec_runoff.push(0);
				p.output.sec_runoff.push(0);
			} else {
				// p.sec_runoff.push((((sm1_before*p.sm1 - r_to_second_layer/1000)/p.sm1) - p.sat)*p.sm1*1000);
				p.output.sec_runoff.push((((sm1_before*p.sm1 - r_to_second_layer/1000)/p.sm1) - p.sat)*p.sm1*1000);
			}
			p.sm1_fraction = Math.min((sm1_before*p.sm1*1000 - r_to_second_layer)/p.sm1/1000, p.sat);
			
			// gw_rech
			// p.gw_rech.push(Math.max((sm2_before - p.fc)*p.sm2*p.daily_perc_factor*1000, 0));
			p.output.gw_rech.push(Math.max((sm2_before - p.fc)*p.sm2*p.daily_perc_factor*1000, 0));
			p.layer2_moisture = Math.min(((sm2_before*p.sm2*1000 - p.output.gw_rech[day])/p.sm2/1000), p.sat);

			// sm
			// p.sm.push((p.sm1_fraction * p.sm1 + p.layer2_moisture * p.sm2) * 1000);
			p.output.sm.push((p.sm1_fraction * p.sm1 + p.layer2_moisture * p.sm2) * 1000);
		});
	}

}


export default Model;


// The following is not required since we are using point-wise model-runs
// export const input_layer_styling_operation = (pixels, data) => {
//   // return [pixels[0][0]+100, pixels[0][1]+150, pixels[0][2]+200, pixels[0][3]];
//   const pixel = [0, 0, 0, pixels[0][3]];
//   const hex_colour_str_without_hash = (pixels[0][2] * 10000).toString(16).padStart(6, '0');
//   pixel[0] = parseInt(hex_colour_str_without_hash.slice(0, 2), 16);
//   pixel[1] = parseInt(hex_colour_str_without_hash.slice(2, 4), 16);
//   pixel[2] = parseInt(hex_colour_str_without_hash.slice(4, 6), 16);
//   return pixel;
// };

// export const input_layer_operation_image_form = (images, data) => {
//   // return [pixels[0][0]+100, pixels[0][1]+150, pixels[0][2]+200, pixels[0][3]];
//   console.log(data.title, images[0].height, images[0].width);
//   data.imageData = images[0];
//   return images[0];
// };


// export const prepare_and_run_model_for_internal_call_testing = pixels => {
//   const pixel = [0, 0, 0, pixels[0][3]];
//   for (let i = 0; i < pixels.length; i++) {
//     for (let j = 0; j < 3; j++) {
//       pixel[j] += pixels[i][j];
//     }
//   }
//   return pixel;
// };
// export const prepare_and_run_model = (pixels, data) => {
//   return prepare_and_run_model_for_internal_call_testing(pixels);
// };

// export const lib = {
//   prepare_and_run_model_for_internal_call_testing
// };