import produce from 'immer';

import * as constants from '../../constants';


const initial_state = {
	enabled: false,
	maxZIndex: 0,
	layers: [{
		title: 'Base-map (OpenStreetMap)',
		visible: true,
		zIndex: 0
	}, {
		title: 'Administrative Boundaries',
		visible: true,
		children: [{
			title: constants.VILLAGE_LAYER_TITLE,
			visible: false,
			zIndex: 1
		}, {
			title: constants.CLUSTER_LAYER_TITLE,
			visible: false,
			zIndex: 2
		}, {
			title: constants.TALUKA_LAYER_TITLE,
			visible: false,
			zIndex: 3
		}, {
			title: constants.DISTRICT_LAYER_TITLE,
			visible: true,
			zIndex: 4
		}]
	}, {
		title: 'Field and Weather Parameters',
		visible: true,
		children: []
	}],
	// temp_style: null,
};

var topmost_layer_of_layers = layers => (
	layers.length > 0 ?	[...layers].filter(l => l.visible).sort((l1, l2) => (l2.zIndex - l1.zIndex))[0] : null
);

const map_add_layer = (state = initial_state, action) => {
	const new_state = produce(state, draft => {
		// console.log(action);
		switch(action.type) {
			case 'TOGGLE_MAP_ADD_LAYER_SELECTED':
				draft.enabled = !draft.enabled;
				break;
			case 'ADD_NEW_LAYER':
				draft.maxZIndex = (1 + draft.maxZIndex) / 2;
				action.payload.zIndex = draft.maxZIndex;
				action.payload.visible = true;
				draft.layers[2].children.push(action.payload);
				break;
			case 'NEW_LAYER_ADDED':
				draft.layers[2].children.forEach(layer => {
					if (layer.pending_request_id === action.payload.pending_request_id) {
						layer.pending_request_id = null;
					}
				});
				break;
			case 'SET_VISIBILITY':
				draft.layers[0].visible = action.payload.checkedKeys.indexOf(draft.layers[0].title) !== -1;
				draft.layers[1].children.forEach(l => {
					l.visible = action.payload.checkedKeys.indexOf(l.title) !== -1;
				});
				draft.layers[2].children.forEach(l => {
					l.visible = action.payload.checkedKeys.indexOf(l.title) !== -1;
				});
				break;
			case 'SET_ADMIN_LAYER_VISIBILITY_DUE_TO_ZOOM':
				draft.layers[1].children.forEach((l, i) => {
					l.visible = action.payload.checked_layer_titles.indexOf(l.title) !== -1;
				})
				break;
			// case 'ADD_TO_SELECTED_PARAM_LAYERS':
			// 	draft.selectedKeys = action.payload.selectedKeys;
			// 	break;
			// case 'REMOVE_SELECTED_LAYERS':
			// 	draft.layers[2].children = draft.layers[2].children.filter(
			// 		l => !draft.selectedKeys.includes(l.title)
			// 	);
			// 	draft.selectedKeys = [];
			// 	break;
			case 'REMOVE_LAYER':
				draft.layers[2].children = draft.layers[2].children.filter(
					l => l.title !== action.payload.title
				);
				break;
			case 'REMOVE_ALL_LAYERS':
				draft.layers[2].children = [];
				break;
			case 'CHANGE_LAYER_STYLE':
// 			// essentially, remove layer and add layer with new style to the same position
// 				// currently, only colour gradient is addressed
// 				const new_style = action.payload.new_style;
// 				let value_colour_pairs = [];
// 				for (const key in new_style.values) {
// 					value_colour_pairs.push([new_style.values[key], new_style.colours[key]]);
// 				}
// 				const legend_gradient_colour_stops = (value_colour_pairs
// 					.sort((e1, e2) => e1[0] - e2[0])
// 					.map((vc, i) => ({
// 						value: vc[0], y_frac: 1-i/(value_colour_pairs.length-1), colour: vc[1]
// 					}))
// 				);
// 				const layer_to_be_changed = [...draft.layers[2].children]
// 					.filter(l => l.visible).sort((l1, l2) => (l2.zIndex - l1.zIndex))[0];

		}
	});
	// console.log(new_state);
	return new_state;
}

export default map_add_layer;