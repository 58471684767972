import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	Popover, Tooltip, Icon, Cascader, Tabs, Tree, Button, Divider,
	Form, Select, InputNumber, DatePicker
} from 'antd';

import moment from 'moment';

import {
	getModelingPageRightSideBarState,
	getRunModelFormState
} from '../redux/selectors';
import {
	set_modeling_layer_visibility,
	get_dynamic_data_and_run_model,
	get_modeling_map_from_points_data
} from '../redux/actions';


class RunModelForm extends Component {

	handleSubmit = (e) => {
		console.log('Received values of the form: ');
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				console.log('Received values of the form: ', values);
				values.region_type = this.props.layer_group.identity_params.region_type;
				values.region_id = this.props.layer_group.identity_params.region_id;
				values.resolution = 100;
				console.log('Values to be sent as request: ', values);
				this.props.get_dynamic_data_and_run_model(this.props.layer_group.title, values);
			}
		});
	}

	render() {
		const { getFieldDecorator } = this.props.form;
		const formSubmitLayout = {
			wrapperCol: { span: 4, offset: 10 }
		}

		const { Option } = Select;
		return (
			
			<Form  layout="vertical">
				
				<Form.Item label="Crop" hasFeedback>
					{getFieldDecorator('crop', {initialValue: 'cotton'})(
						<Select>
							<Option value="cotton">Cotton</Option>
							<Option value="tur">Tur</Option>
						</Select>
					)}
				</Form.Item>
				
				<Form.Item label="Sowing Threshold" hasFeedback>
					{getFieldDecorator('sowing_threshold', {initialValue: 30})(
						<InputNumber
							min={0}
							formatter={value => `${value} mm`}
							parser={value => parseInt(value.replace(' mm', ''))}
						/>
					)}
				</Form.Item>

				<Form.Item label="Monsoon Year (Eg: 2018 is Jun-18 to May-19)" hasFeedback>
					{getFieldDecorator('year', {initialValue: 2017})(
						<InputNumber
							min={2013}
							max={2018}
						/>
					)}
				</Form.Item>

				<Form.Item {...formSubmitLayout}>
					<Button
						type="primary"
						onClick={this.handleSubmit}
					>
						Run
					</Button>
				</Form.Item>

			</Form>
		);
	}
}

const mapDataToProps = getRunModelFormState;
const mapActionToProps = {
	get_dynamic_data_and_run_model
};
const WrappedRunModelForm = connect(
	mapDataToProps,
	mapActionToProps
)(Form.create({name: 'run_model_form'})(RunModelForm));


class AddModelledLayerForm extends Component {
	handleSubmit = e => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				console.log('Received values of the form: ', values);
				console.log('Layer Subgroup data: ', this.props.layer_subgroup);
		// 		console.log('Values to be sent as request: ', values);
				const rain_doy_index = values.date.diff(moment(new Date(values.date.year(), 5, 1)), 'days');
				console.log(moment(new Date(values.date.year(), 6, 1)), rain_doy_index);
				this.props.get_modeling_map_from_points_data({
					layer_request_params: {
						resolution: 100,
						layer_name: `${values.parameter} on ${values.date.format('YYYY-MM-DD')}`,
						points_data: this.props.layer_subgroup.identity_params.points.map((p, i) => {
							// if (i === 100) console.log(p[values.parameter]);
							return {x: p.x, y: p.y, data: p[values.parameter][rain_doy_index]};
						})
					},
					layer_group_title: this.props.layer_group_title,
					layer_subgroup_title: this.props.layer_subgroup.title,
				});
			}
		});
	}

	render() {
		const { Option } = Select;
		const { getFieldDecorator } = this.props.form;
		return (
			<Form layout="vertical" style={{width: 400}}>
				
				<Form.Item label="Parameter: (Estimated (E), Monitored(M))" hasFeedback>
					{getFieldDecorator('parameter', {initialValue: 'aet'})(
						<Select>
							<Option value="accum_sm">Available Soil Moisture On Date (E)</Option>
							<Option value="pri_runoff">Primary Runoff on Date (E)</Option>
							<Option value="infil">Infiltration on Date (E)</Option>
							<Option value="pet">PET on Date (E)</Option>
							<Option value="aet">AET on Date (E)</Option>
							<Option value="sec_runoff">Secondary Runoff on Date (E)</Option>
							<Option value="gw_rech">Groundwater Recharge on Date (E)</Option>
							<Option value="rain">Rain on Date (M)</Option>
							<Option value="accum_pri_runoff">Primary Runoff Till Date (E)</Option>
							<Option value="accum_infil">Infiltration Till Date (E)</Option>
							<Option value="accum_pet">PET Till Date (E)</Option>
							<Option value="accum_aet">AET Till Date (E)</Option>
							<Option value="accum_sec_runoff">Secondary Runoff Till Date (E)</Option>
							<Option value="accum_gw_rech">Groundwater Recharge Till Date (E)</Option>
							<Option value="accum_rain">Total Rain Till Date (M)</Option>
						</Select>
					)}
				</Form.Item>
				
				<Form.Item label="Date" hasFeedback>
					{getFieldDecorator('date', {
						initialValue: moment('2019-06-20')
					})(
						<DatePicker allowClear={false}/>
					)}
				</Form.Item>
				
				<Form.Item wrapperCol={{ span: 4, offset: 10 }}>
					<Button
						type="primary"
						onClick={this.handleSubmit}
					>
						Add
					</Button>
				</Form.Item>

			</Form>
		);
	}
}

const WrappedAddModelledLayerForm = connect(
	null,
	{ get_modeling_map_from_points_data }
)(Form.create({name: 'add_modelled_layer_form'})(AddModelledLayerForm));

class ModelingGeounitLayerGroupToolsWidget extends Component {

	constructor(props) {
		super(props);
		this.state = {
			// colour_stops: JSON.parse(JSON.stringify(this.props.colour_stops)),
		};
	}

	render() {
		
		return (
		<React.Fragment>
			<Button ghost type="danger" onClick={e => this.props.remove_layer_group(this.props.layer_group.title)}>
				Remove Layer Group
			</Button>
			<Divider />
			<div>
				<p style={{fontWeight: 'bold'}}>Simulate a model configuration</p>
			</div>
			<WrappedRunModelForm layer_group={this.props.layer_group} />
		</React.Fragment>
		);
	}
}

class ModelingRunConfigurationLayerSubGroupToolsWidget extends Component {

	constructor(props) {
		super(props);

		this.state = {
			// colour_stops: JSON.parse(JSON.stringify(this.props.colour_stops)),
		};
	}

	render() {
		return (
		<React.Fragment>
			<Button ghost type="danger" onClick={e => this.props.remove_layer_group(this.props.layer_subgroup.title)}>
				Remove Layer Group
			</Button>
			<Divider />
			{/*
			<p style={{fontWeight: 'bold'}}>Change Colour Style</p>
			<div style={{width: 300, minHeight: 200, maxHeight: 200, overflowY: 'scroll'}}>
			{this.state.colour_stops.map((cs, cs_idx) => (
				<Row type="flex" align="middle" justify="space-around" style={{height: 50}} key={cs_idx.toString()}>
					<Col span={6}>
						<InputNumber
							value={cs.value}
							onChange={value => {
								let new_colour_stops = [...this.state.colour_stops];
								new_colour_stops[cs_idx].value = value;
								this.setState({
									colour_stops: new_colour_stops,
								});
							}}
						/>
					</Col>
					<Col span={6}>
						<Popover
							placement="bottom"
			      			content={
			      				<ChromePicker
			      					color={cs.colour}
									onChangeComplete={(colour, event) => {
										console.log(colour);
										let new_colour_stops = [...this.state.colour_stops];
										new_colour_stops[cs_idx].colour = colour.hex;
										new_colour_stops[cs_idx].rgba = colour.rgb;
										this.setState({
											colour_stops: new_colour_stops,
										});
									}}
								/>
							}
		      			>
			      			<div style={{
		      					width: '100%', height: 16,
		      					background: 'rgba(' + cs.rgba.r+','+cs.rgba.g+','+cs.rgba.b+','+cs.rgba.a + ')'
		      				}}/>
			      		</Popover>
					</Col>
				</Row>
				)
			)}
				<div style={{textAlign: 'center', marginTop: 10}}>
					<Button
						style={{marginRight: 10}}
						onClick={e => this.setState({
							colour_stops: JSON.parse(JSON.stringify(this.props.colour_stops))
						})}
					>
						Reset
					</Button>
					<Button
						type="primary"
						style={{marginLeft: 10}}
						onClick={e => this.props.change_layer_style(this.state)}
					>
						Apply
					</Button>
				</div>
			</div>
			*/}
			<div>
				<p style={{fontWeight: 'bold'}}>Add Layer</p>
				<WrappedAddModelledLayerForm
					layer_group_title={this.props.layer_group_title}
					layer_subgroup={this.props.layer_subgroup}
				/>
			</div>
		</React.Fragment>
		);
	}
}

class ModelingPageRightSideBar extends Component {

	build_layers_subtree(data_obj) {

		return Object.entries(data_obj).map(([k, v]) => {
			if (typeof v === 'object') {
				return (
					<Tree.TreeNode
						title={
							<span style={{fontSize: '1.1em', fontWeight: 'bold'}}>{ k }</span>
						}
						key={k.replace(/[\W]+/g, '')}
					>
						{this.build_point_data_subtree(v)}
					</Tree.TreeNode>
				);
			} else {
				return (
					<Tree.TreeNode
						title={
							<span style={{fontWeight: 'bold'}}>
								{ k } : <span style={{fontWeight: 'normal'}}>{ v }</span>
							</span>
						}
						key={k.replace(/[\W]+/g, '')}
					/>
				);
			}
		});
	}

	build_point_data_subtree(data_obj) {
		return Object.entries(data_obj).map(([k, v]) => {
			if (typeof v === 'object') {
				return (
					<Tree.TreeNode
						title={
							<span style={{fontSize: '1.1em', fontWeight: 'bold'}}>{ k }</span>
						}
						key={k.replace(/[\W]+/g, '')}
					>
						{this.build_point_data_subtree(v)}
					</Tree.TreeNode>
				);
			} else {
				return (
					<Tree.TreeNode
						title={
							<span style={{fontWeight: 'bold'}}>
								{ k } : <span style={{fontWeight: 'normal'}}>{ v }</span>
							</span>
						}
						key={k.replace(/[\W]+/g, '')}
					/>
				);
			}
		});
	}

	
	render() {
		const { layers } = this.props;
		const layer_tree_checkedKeys = [];
		if (layers[0].visible) layer_tree_checkedKeys.push(layers[0].title);
		layers[1].children.forEach(l => {
			if (l.visible) layer_tree_checkedKeys.push(l.title);
		});
		layers.forEach((lg, idx) => {
			if (idx < 2) return;
			lg.children.forEach(l => {
				if (l.visible) layer_tree_checkedKeys.push(l.title);
			});
		});
		// const build_modeling_layer_groups => () => {
		// 	let layer_group_nodes = [];
		// 	for (let i=2; i<layers.length; i++) {
		// 		layer_group_nodes.push(
		// 			<Tree.TreeNode
		// 				title={<span style={{fontWeight: 'bold'}}>{ layers[i].title }</span>}
		// 				key={layers[i].title}
		// 			>
		// 				{[...layers[i].children].sort((l1, l2) => (l2.zIndex - l1.zIndex)).map(
		// 					l => <Tree.TreeNode title={l.title} key={l.title} />
		// 				)}
		// 			</Tree.TreeNode>
		// 		)
		// 	}
		// 	return layer_group_nodes;
		// };

		const layers_tree = (
			<Tree
				blockNode
				checkable
				onCheck={this.props.set_modeling_layer_visibility}
				defaultCheckedKeys={[layers[0].title, layers[1].children[3].title]}
				checkedKeys={layer_tree_checkedKeys}
				defaultExpandedKeys={[layers[1].title]}
				style={{minHeight: 300, maxHeight: 300, overflow: 'scroll', borderBottom: 'solid 1px #ccc'}}
			>
				<Tree.TreeNode title={layers[0].title} key={layers[0].title} />
				<Tree.TreeNode
					title={<span style={{fontWeight: 'bold'}}>{ layers[1].title }</span>}
					key={layers[1].title}
				>
					<Tree.TreeNode title={layers[1].children[0].title} key={layers[1].children[0].title} />
					<Tree.TreeNode title={layers[1].children[1].title} key={layers[1].children[1].title} />
					<Tree.TreeNode title={layers[1].children[2].title} key={layers[1].children[2].title} />
					<Tree.TreeNode title={layers[1].children[3].title} key={layers[1].children[3].title} />
				</Tree.TreeNode>
				{
				layers.filter((lg, idx) => idx > 1).map((layer_group, lg_idx) => (
					<Tree.TreeNode
						title={
							<React.Fragment>
								<Popover
									content={
										<ModelingGeounitLayerGroupToolsWidget
											layer_group={layer_group}
											remove_layer_group={null/*remove_layer_group*/}
										/>
									}
									placement="left"
									mouseEnterDelay={0.5}
								>
									<span style={{paddingRight: 10, color: 'blue'}}>
										<Icon type="tool" />
									</span>
								</Popover>
								<span style={{fontWeight: 'bold'}}>{ layer_group.title }</span>
							</React.Fragment>
						}
						key={layer_group.title}
					>
						{
						layer_group.children.map(layer_subgroup => (layer_subgroup.children ? (
							<Tree.TreeNode
								title={
									<React.Fragment>
										<Popover
											content={
												<ModelingRunConfigurationLayerSubGroupToolsWidget
													layer_group_title={layer_group.title}
													layer_subgroup={layer_subgroup}
													remove_layer_group={null/*remove_layer_group*/}
												/>
											}
											placement="left"
											mouseEnterDelay={0.5}
										>
											<span style={{paddingRight: 10, color: 'blue'}}>
												<Icon type="tool" />
											</span>
										</Popover>
										<span style={{fontWeight: 'bold'}}>{ layer_subgroup.title }</span>
									</React.Fragment>
								}
								key={layer_subgroup.title}
							>
								{[...layer_subgroup.children].sort((l1, l2) => (l2.zIndex - l1.zIndex)).map(
									l => <Tree.TreeNode title={l.title} key={l.title} />
								)}
							</Tree.TreeNode>
						) : <Tree.TreeNode title={layer_subgroup.title} key={layer_subgroup.title} />))
						}
					</Tree.TreeNode>
				))
				}
				{/*build_modeling_layer_groups()*/}
			</Tree>
		);
		return (
			<Tabs
				defaultActiveKey="Layers"
				forceRender={true}
			>
				<Tabs.TabPane tab="Layers" key="Layers" style={{maxHeight: '100%'}}>
					{layers_tree}
				</Tabs.TabPane>
				{/*
				<Tabs.TabPane tab="Points" key="Points" style={{maxHeight: '100%'}}>
					{point_data_tree}
				</Tabs.TabPane>
				*/}
			</Tabs>
		);
	}
}

const mapStateToProps = getModelingPageRightSideBarState;
const mapDispatchToProps = {
	set_modeling_layer_visibility
};


export default connect(mapStateToProps, mapDispatchToProps)(ModelingPageRightSideBar);