const initial_state = {
	enabled: false,
	coordinates: null,
	point_data: null
};

const map_identify_point = (state = initial_state, action) => {
	switch(action.type) {
		case 'TOGGLE_MAP_POINT_IDENTIFY_CURSOR':
			return {
				...state,
				enabled: !state.enabled
			};
		case 'CHANGE_IDENTIFY_POINT_COORDINATES':
			return {
				...state,
				coordinates: action.payload.coordinates
			};
		case 'DISPLAY_IDENTIFY_POINT_DATA':
			return {
				...state,
				point_data: action.payload.point_data
			}
		default:
			return state;
	}
}

export default map_identify_point;