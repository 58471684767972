export const TOP_MENU_SELECT = 'TOP_MENU_SELECT';
export const TOGGLE_MAP_POINT_IDENTIFY_CURSOR = 'TOGGLE_MAP_POINT_IDENTIFY_CURSOR';
export const CHANGE_IDENTIFY_POINT_COORDINATES = 'CHANGE_IDENTIFY_POINT_COORDINATES';
export const DISPLAY_IDENTIFY_POINT_DATA = 'DISPLAY_IDENTIFY_POINT_DATA';
export const TOGGLE_MAP_ADD_LAYER_SELECTED = 'TOGGLE_MAP_ADD_LAYER_SELECTED';
export const SET_REGION_HIERARCHY_DATA = 'SET_REGION_HIERARCHY_DATA';
export const PAN_TO_REGION_EXTENT = 'PAN_TO_REGION_EXTENT';
export const SET_VISIBILITY = 'SET_VISIBILITY';
export const CHANGE_LAYER_STYLE = 'CHANGE_LAYER_STYLE';
export const ADD_TO_SELECTED_PARAM_LAYERS = 'ADD_TO_SELECTED_PARAM_LAYERS';
// export const REMOVE_SELECTED_LAYERS = 'REMOVE_SELECTED_LAYERS';
export const REMOVE_ALL_LAYERS = 'REMOVE_ALL_LAYERS';
export const REMOVE_LAYER = 'REMOVE_LAYER';
export const SET_ADMIN_LAYER_VISIBILITY_DUE_TO_ZOOM = 'SET_ADMIN_LAYER_VISIBILITY_DUE_TO_ZOOM';
export const ADD_NEW_LAYER = 'ADD_NEW_LAYER';
export const NEW_LAYER_ADDED = 'NEW_LAYER_ADDED';
export const TOGGLE_REGION_SELECTION_POPOVER_VISIBLE = 'TOGGLE_REGION_SELECTION_POPOVER_VISIBLE';
export const SET_SELECTED_MODELING_REGION = 'SET_SELECTED_MODELING_REGION';
export const SET_MODELING_VISIBILITY = 'SET_MODELING_VISIBILITY';
export const SET_MODELING_LAYER_VISIBILITY = 'SET_MODELING_LAYER_VISIBILITY';
export const SET_MODELING_ADMIN_LAYER_VISIBILITY_DUE_TO_ZOOM = 'SET_MODELING_ADMIN_LAYER_VISIBILITY_DUE_TO_ZOOM';
export const SET_MODELING_REGION_STATIC_DATA = 'SET_MODELING_REGION_STATIC_DATA';
export const ADD_MODEL_CONFIGURATION_LAYER_SUBGROUP = 'ADD_MODEL_CONFIGURATION_LAYER_SUBGROUP';
// export const ADD_MODELING_REGION_LAYERS = 'ADD_MODELING_REGION_LAYERS';
export const PAN_TO_MODELING_REGION_EXTENT = 'PAN_TO_MODELING_REGION_EXTENT';
export const ADD_MODELING_REGION_LAYER_GROUP = 'ADD_MODELING_REGION_LAYER_GROUP';
export const ADD_LAYER_TO_MODELING_REGION_LAYER_GROUP = 'ADD_LAYER_TO_MODELING_REGION_LAYER_GROUP';
export const RUN_MODEL = 'RUN_MODEL';
export const SET_DATABASES = 'SET_DATABASES';
export const ADD_DATA_TABLE = 'ADD_DATA_TABLE';
export const UPDATE_DATA_TABLE = 'UPDATE_DATA_TABLE';
export const SET_DATA_TABLE_LOADING = 'SET_DATA_TABLE_LOADING';
export const REMOVE_DATA_TABLE = 'REMOVE_DATA_TABLE';
export const CHANGE_DATA_TAB_PANE = 'CHANGE_DATA_TAB_PANE';