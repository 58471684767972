import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	Popover, Tooltip, Icon, Cascader,
	// Tabs, Tree
} from 'antd';

import { getMapsPageState } from '../redux/selectors';
import {
	toggle_map_point_identify_cursor,
	toggle_map_add_layer_selected,
	pan_to_region_extent
} from '../redux/actions';
import MapComponent from './MapComponent';
import AddLayerForm from './AddLayerForm';
import MapsPageRightSideBar from './MapsPageRightSideBar';


class MapsPage extends Component {

	render() {

		const {
			region_hierarchy_data,
			map_identify_point,
			map_add_layer,
			toggle_map_point_identify_cursor,
			toggle_map_add_layer_selected,
			pan_to_region_extent
		} = this.props;
		// console.log('from MapsPage : ', this.props)

		const popover = (
			<div>
				Popover
			</div>
		);

		const region_pan_cascader = (
			<Cascader
				options={region_hierarchy_data}
				changeOnSelect={true}
				onChange={pan_to_region_extent}
				style={{width: 400}}
			/>
		);

		return (
			<React.Fragment>
				<div id="maps-left-sidebar-grid">
					<Popover
						content={region_pan_cascader}
						placement="right"
						title="Pan to an administrative region"
						trigger="click"
					>
						<button><Icon type="fullscreen" /></button>
					</Popover>
					<Popover content={<AddLayerForm/>} placement="right" title="Add new layer" trigger="click">
						<Tooltip placement="right" title="Add New Layer" trigger="hover">
							<button
								className={map_add_layer.enabled ? 'selected' : ''}
								onClick={toggle_map_add_layer_selected}
							>
								<Icon type="plus-square" />
							</button>
						</Tooltip>
					</Popover>
					<Tooltip placement="right" title="Identify">
						<button
							className={map_identify_point.enabled ? 'selected' : ''}
							onClick={toggle_map_point_identify_cursor}
						>
							I
						</button>
					</Tooltip>
					{/*
					<Tooltip placement="right" title="Download">
						<button><Icon type="download" /></button>
					</Tooltip>
					{/*
					*/}
				</div>
				<div id="maps-content" className={map_identify_point.enabled ? 'cursor-help' : ''}>
					<MapComponent />
				</div>
				<div id="maps-right-sidebar-grid">
					<MapsPageRightSideBar />
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = getMapsPageState;
const mapDispatchToProps = {
	toggle_map_point_identify_cursor,
	toggle_map_add_layer_selected,
	pan_to_region_extent
};

export default connect(mapStateToProps, mapDispatchToProps)(MapsPage);