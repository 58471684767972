import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	Popover, Tooltip, Icon, Cascader, Button,
	Form, Select, InputNumber
	// Tabs, Tree
} from 'antd';

import {
	getModelingPageState,
	getRunModelFormState
} from '../redux/selectors';
import {
	toggle_map_point_identify_cursor,
	toggle_region_selection_popover_visible,
	set_selected_modeling_region,
	set_modeling_region_static_data,
	get_dynamic_data_and_run_model,
	run_model
} from '../redux/actions';
import ModelingMapComponent from './ModelingMapComponent';
// import AddLayerForm from './AddLayerForm';
import ModelingPageRightSideBar from './ModelingPageRightSideBar';


// class RunModelForm extends Component {

// 	handleSubmit = (e) => {
// 		e.preventDefault();
// 		this.props.form.validateFields((err, values) => {
// 			if (!err) {
// 				console.log('Received values of the form: ', values);
// 				if (!this.props.selected_region || this.props.selected_region.length == 0) {
// 					return;
// 				}
// 				values.region_type = ['district', 'taluka', 'cluster', 'village'][this.props.selected_region.length-1]
// 				values.region_id = this.props.selected_region[this.props.selected_region.length-1].value;
// 				values.resolution = 100;
// 				console.log('Values to be sent as request: ', values);
// 				this.props.get_dynamic_data_and_run_model(values);
// 			}
// 		});
// 	}

// 	render() {
// 		const { getFieldDecorator } = this.props.form;
// 		const formSubmitLayout = {
// 			wrapperCol: { span: 4, offset: 10 }
// 		}

// 		const { Option } = Select;
// 		return (
			
// 			<Form  layout="vertical" onSubmit={this.handleSubmit}>
				
// 				<Form.Item label="Crop" hasFeedback>
// 					{getFieldDecorator('crop', {initialValue: 'cotton'})(
// 						<Select>
// 							<Option value="cotton">Cotton</Option>
// 							<Option value="tur">Tur</Option>
// 						</Select>
// 					)}
// 				</Form.Item>
				
// 				<Form.Item label="Sowing Threshold" hasFeedback>
// 					{getFieldDecorator('sowing_threshold', {initialValue: 30})(
// 						<InputNumber
// 							min={0}
// 							formatter={value => `${value} mm`}
// 							parser={value => parseInt(value.replace(' mm', ''))}
// 						/>
// 					)}
// 				</Form.Item>

// 				<Form.Item label="Monsoon Year (Eg: 2018 is Jun-18 to May-19)" hasFeedback>
// 					{getFieldDecorator('year', {initialValue: 2017})(
// 						<InputNumber
// 							min={2013}
// 							max={2018}
// 						/>
// 					)}
// 				</Form.Item>

// 				<Form.Item {...formSubmitLayout}>
// 					<Button
// 						type="primary"
// 						htmlType="submit"
// 					>
// 						Run
// 					</Button>
// 				</Form.Item>

// 			</Form>
// 		);
// 	}
// }

// const mapDataToProps = getRunModelFormState;
// const mapActionToProps = {
// 	get_dynamic_data_and_run_model
// };
// const WrappedRunModelForm = connect(
// 	mapDataToProps,
// 	mapActionToProps
// )(Form.create({name: 'run_model_form'})(RunModelForm));


class ModelingPage extends Component {

	render() {

		const {
			region_hierarchy_data,
			region_selection_popover_visible,
			toggle_region_selection_popover_visible,
			set_selected_modeling_region,
			set_modeling_region_static_data,
			run_model
		} = this.props;
		// console.log('from MapsPage : ', this.props)

		const popover = (
			<div>
				Popover
			</div>
		);

		const region_pan_cascader = (
			<React.Fragment>
				<Cascader
					options={region_hierarchy_data}
					changeOnSelect={true}
					onChange={set_selected_modeling_region}
					style={{width: 400}}
				/>
				<div style={{textAlign: 'center', paddingTop: 10}}>
					<Button
						type="primary"
						onClick={set_modeling_region_static_data}
					>
						Get Field Data
					</Button>
				</div>
			</React.Fragment>
		);

		return (
			<React.Fragment>
				<div id="modeling-left-sidebar-grid">
					<Popover
						content={region_pan_cascader}
						placement="right"
						title="Region for modeling"
						trigger="click"
					>
						<button><Icon type="fullscreen" /></button>
					</Popover>
					<Tooltip placement="right" title="Identify">
						<button
							onClick={toggle_map_point_identify_cursor}
						>
							I
						</button>
					</Tooltip>
					{/*
					<Popover
						content={<WrappedRunModelForm />}
						placement="right"
						title="Run Model"
						trigger="click"
					>
						<button>R</button>
					</Popover>
					*/}
					<Tooltip placement="right" title="Download">
						<button><Icon type="download" /></button>
					</Tooltip>
					<Popover content={popover} placement="rightTop" title="Draw/Measure">
						<button><Icon type="edit" /></button>
					</Popover>
					<Popover content={popover} placement="rightTop" title="Styling">
						<button>S</button>
					</Popover>
				</div>
				<div id="modeling-content" >
					<ModelingMapComponent />
				</div>
				<div id="modeling-right-sidebar-grid">
					<ModelingPageRightSideBar />
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = getModelingPageState;
const mapDispatchToProps = {
	// toggle_map_point_identify_cursor,
	// toggle_map_add_layer_selected,
	toggle_region_selection_popover_visible,
	set_selected_modeling_region,
	set_modeling_region_static_data,
	run_model
	// set_modeling_region_static_data,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ModelingPage);