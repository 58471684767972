class ServerCommunication {
  
  static async GET({endpoint, format='plain', params={}}) {
    const url = new URL(endpoint, 'http://localhost');
    for (const [name, value] of Object.entries(params)) {
      url.searchParams.set(name, value);
    }
    let response_text = await fetch(`${url.pathname}${url.search}`)
    .then(response => {
      if (response.ok) {
        return response;
      } else {
        throw new Error(` in GET communication: ${response.status} ${response.statusText}`);
      }
    })
    .then(response => {
      if (format === 'plain') {
        return response.text();
      } else if (format === 'json') {
        return response.json()
      }
    })
    // .then(response_text => response_text)
    // .then(response_text => {
    //   console.log('test response: ' + response_text);
    // })
    .catch(error => {
      console.log(error.message);
    });

    // console.log('test response: ' + response_text);

    return response_text;
  }
  
  static async POST({endpoint, format='plain', params={}}) {
    // Have to use URLSearchParams instead of FormData for reason given in
    // https://stackoverflow.com/questions/46640024/how-do-i-post-form-data-with-fetch-api
    let uSP = new URLSearchParams();
    for (const [name, value] of Object.entries(params)) {
      uSP.append(name, JSON.stringify(value));
    }
    let response = await fetch(
      endpoint, {
        method: 'POST',
        body: uSP
      }
    ).then(response => {
      if (response.ok) {
        return response;
      } else {
        throw new Error(` in POST communication: ${response.status} ${response.statusText}`);
      }
    }).then(response => {
      if (format === 'plain') {
        return response.text();
      } else if (format === 'json') {
        return response.json()
      }
    }).catch(error => {
      console.log(error.message);
    });

    // console.log('test response: ' + response_text);

    return response;
  }

}

export default ServerCommunication;