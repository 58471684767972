import React, { Component } from 'react';
import { connect } from 'react-redux';

import './App.css';
import 'antd/dist/antd.css';

import MapsPage from './components/MapsPage';
import DatabasesPage from './components/DatabasesPage';
import ModelingPage from './components/ModelingPage';

import { getAppState } from './redux/selectors';
import {
	top_menu_select,
	set_region_hierarchy_data,
	set_databases
} from './redux/actions';

// import C from './server_communication';


class App extends Component {

	constructor(props) {
		super(props);

		props.set_region_hierarchy_data();
		props.set_databases();
	}

	render() {
		
		const { top_menu, top_menu_select } = this.props;
		// console.log(top_menu);
		
		return (
			<div id="main-grid">
				<div id="header-grid">
					<div id="title-grid">
						<h1 id="title">PoCRA GIS Dashboard</h1>
					</div>
					<div id="top-menu-grid">
						<button
							className={top_menu === 'Maps' ? 'selected' : ''}
							onClick={() => top_menu_select('Maps')}
						>
							Maps
						</button>
						
						<button
							className={top_menu === 'Databases' ? 'selected' : ''}
							onClick={() => top_menu_select('Databases')}
						>
							Database
						</button>
						
						<button onClick={() => {
							window.open("https://dashboards.satsure.co/#/site/PoCRA/views/PoCRA/PoCRA");
						}}>
							Startups
						</button>

						{/*
						<button
							className={top_menu === 'Modeling' ? 'selected' : ''}
							onClick={() => top_menu_select('Modeling')}
						>
							Modeling
						</button>
						{/*
						<button
							className={top_menu === 'Tools' ? 'selected' : ''}
							onClick={() => top_menu_select('Tools')}
						>
							Tools
						</button>
						*/}
					</div>
				</div>
				<div id="main-content-grid">
					<div id="maps-main-content-grid" style={{display: top_menu === 'Maps' ? 'contents' : 'none'}}>
						<MapsPage />
					</div>
					<div id="databases-main-content-grid" style={{display: top_menu === 'Databases' ? 'contents' : 'none'}}>
						<DatabasesPage />
					</div>
					
					<div style={{display: top_menu === 'Modeling' ? 'contents' : 'none'}}>
						<ModelingPage />
					</div>
					
					<div style={{display: top_menu === 'Tools' ? 'contents' : 'none'}}>
						Tools
					</div>
				</div>
			</div>
		);
  }
}

const mapStateToProps = getAppState;
const mapDispatchToProps = {
	top_menu_select,
	set_region_hierarchy_data,
	set_databases
};

const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App);

export default ConnectedApp;