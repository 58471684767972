export const getAppState = state => ({top_menu: state.top_menu});

export const getMapsPageState = state => ({
	region_hierarchy_data: state.region_hierarchy_data,
	map_identify_point: state.map_identify_point,
	map_add_layer: state.map_add_layer
});

export const getMapComponentState = state => ({
	top_menu: state.top_menu,
	map_identify_point: state.map_identify_point,
	map_pan_region_extent: state.map_pan_region_extent,
	map_add_layer: state.map_add_layer
});

export const getMapsPageRightSideBarState = state => ({
	map_identify_point: state.map_identify_point,
	map_add_layer: state.map_add_layer
});

export const getChangeStyleWidgetState = state => ({
	param_layers: state.map_add_layer.layers[2].children,
	// temp_style: state.map_add_layers.temp_style
});

export const getModelingPageState = state => ({
	region_hierarchy_data: state.region_hierarchy_data,
	modeling: state.modeling
});

export const getRunModelFormState = state => ({
	selected_region: state.modeling.selected_region
});

export const getModelingMapComponentState = state => ({
	top_menu: state.top_menu,
	modeling: state.modeling
});

export const getModelingPageRightSideBarState = state => ({
	layers: state.modeling.layers
});

export const getDatabasesPageState = state => ({...state.databases});