import React, { Component } from 'react';

import { connect } from 'react-redux';

import 'antd/dist/antd.css';

import {
	Form, Select, DatePicker, Button,
	TreeSelect, Input, Dropdown, Menu
	// Drawer, Tabs, Input, Cascader, InputNumber
} from 'antd';

import moment from 'moment';

import * as constants from '../constants';

import { add_new_layer/*, get_new_layer_url*/ } from '../redux/actions';
import { PARAM_LABELS, PARAM_UNITS } from '../constants';


class AddLayerForm extends Component {

	constructor(props) {
		super(props);
		this.params = [

		];
		this.state = {
			dataSource: ['a', 'b', 'c']
		};
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				// console.log('Received values of the form: ', values);
				// return;
				values.date = values.date.format('YYYY-MM-DD');
				values.region_type = 'all';
				values.region_id = 'all';
				values.title = `${PARAM_LABELS[values.parameter]}` + // parameter
					` ${values.layer_type}` + // layer-type
					` (${values.parameter === 'rain' ? '' : values.crop + ', '}` + // crop
					`${values.date})` // date
				values.legend_gradient_colour_stops = constants.PARAM_LEGEND_GRADIENT_COLOUR_STOPS[values.parameter];
				values.measurement_unit = PARAM_UNITS[values.parameter];
				console.log('Values from the form: ', values);
				this.props.dispatch(add_new_layer(values));
				// this.props.api.set_layer_request({title: title, layer_params: values});
			}
		});
	}

	render() {
		const { getFieldDecorator } = this.props.form;
		const formSubmitLayout = {
			wrapperCol: { span: 4, offset: 10 }
		}

		const layer_types = [{
			title: 'Raster', value: 'Raster', key: 'Raster'
		}, {
			title: 'Village-wise', value: 'villages', key: 'villages', selectable: false, children: [{
				title: 'Village-wise Average', value: 'Village-wise Average', key: 'Village-wise Average'
			}, {
				title: 'Village-wise Std. Deviation', value: 'Village-wise Std. Deviation', key: 'Village-wise Std. Deviation'
			}]
		}, {
			title: 'Cluster-wise', value: 'clusters', key: 'clusters',  selectable: false, children: [{
				title: 'Cluster-wise Average', value: 'Cluster-wise Average', key: 'Cluster-wise Average'
			}, {
				title: 'Cluster-wise Std. Deviation', value: 'Cluster-wise Std. Deviation', key: 'Cluster-wise Std. Deviation'
			}]
		}, {
			title: 'Taluka-wise', value: 'talukas', key: 'talukas',  selectable: false, children: [{
				title: 'Taluka-wise Average', value: 'Taluka-wise Average', key: 'Taluka-wise Average'
			}, {
				title: 'Taluka-wise Std. Deviation', value: 'Taluka-wise Std. Deviation', key: 'Taluka-wise Std. Deviation'
			}]
		}, {
			title: 'District-wise', value: 'districts', key: 'districts',  selectable: false, children: [{
				title: 'District-wise Average', value: 'District-wise Average', key: 'District-wise Average'
			}, {
				title: 'District-wise Std. Deviation', value: 'District-wise Std. Deviation', key: 'District-wise Std. Deviation'
			}]
		}]

		const field_params_non_sm = [
			{value: 'pri_runoff', label: 'Primary Runoff'}, {value: 'infil', label: 'Infiltration'},
			{value: 'pet', label: 'Potential Evapotranspiration'}, {value: 'aet', label: 'Actual Evapotranspiration'},
			{value: 'sec_runoff', label: 'Secondary Runoff'}, {value: 'gw_rech', label: 'Groundwater Recharge'},
		].map(({value, label}) => ({
			title: label, key: label, selectable: false, children: [{
				title: 'On Selected Date', value: value, key: value
			}, {
				title: 'Total from June 1st to Selected Date', value: 'accum_'+value, key: 'accum_'+value
			}],
		}));
		const weather_params = {
			title: 'Weather (measured)', key: 'Weather', selectable: false,
			children: [
				{value: 'temp', label: 'Temperature on Selected Date'},
				{value: 'rh', label: 'Relative Humidity on Selected Date'},
				{value: 'wind', label: 'Wind Speed on Selected Date'},
			].map(({value, label}) => ({
				title: label, key: label, selectable: false,
				children: [
					{stat_label: 'min', stat_label: 'Minimum'},
					{stat_label: 'max', stat_label: 'Maximum'},
					{stat_label: 'avg', stat_label: 'Average'},
				].map(({stat_label, stat_name}) => (
					{title: stat_label, value: value+'_'+stat_name, key: value+'_'+stat_name}
				))
			}))
		};
		const all_params = [{
			title: 'Field (estimated)', key: 'Field', selectable: false, children: [{
				title: 'Available Soil Moisture On Selected Date', value: 'accum_sm', key: 'Available Soil Moisture'
			}].concat(field_params_non_sm)
		}].concat(weather_params);

		const { Option } = Select;
		return (
			
			<Form layout="vertical" onSubmit={this.handleSubmit} style={{width: 400}}>
				
				<Form.Item label="Crop" hasFeedback>
					{getFieldDecorator('crop', {initialValue: 'cotton'})(
						<Select>
							<Option value="cotton">Cotton</Option>
							<Option value="soyabean">Soyabean</Option>
						</Select>
					)}
				</Form.Item>

				<Form.Item label="Date" hasFeedback>
					{getFieldDecorator('date', {
						initialValue: moment().utc().subtract( 
							moment().utc().hour() > 9 ? 1 : 2, 'days'
						)
					})(
						<DatePicker
							allowClear={false}
							disabledDate={
								date => (
									date.isBefore(moment('2019-06-01'))
									|| date.isAfter(moment().utc().subtract(
										moment().utc().hour() > 9 ? 1 : 2, 'days'
									))
								)
							}
						/>
					)}
				</Form.Item>

				<Form.Item label="Parameter: (Estimated (E), Monitored(M))" hasFeedback>
					{getFieldDecorator('parameter', {initialValue: 'accum_sm'})(
						<Select>
							<Option value="rain">Rain on Date (M)</Option>
							<Option value="accum_rain">Total Rain Till Date (M)</Option>
							<Option value="max_dry_spell_len">Maximum Dry-Spell (M)</Option>
							<Option value="curr_dry_spell_len">Current Dry-Spell (M)</Option>
							<Option value="accum_sm">Available Soil Moisture On Date (E)</Option>
							<Option value="pri_runoff">Primary Runoff on Date (E)</Option>
							<Option value="infil">Infiltration on Date (E)</Option>
							<Option value="pet">PET on Date (E)</Option>
							<Option value="aet">AET on Date (E)</Option>
							<Option value="deficit">Crop Deficit on Date (E)</Option>
							<Option value="sec_runoff">Secondary Runoff on Date (E)</Option>
							<Option value="gw_rech">Groundwater Recharge on Date (E)</Option>
							<Option value="accum_pri_runoff">Primary Runoff Till Date (E)</Option>
							<Option value="accum_infil">Infiltration Till Date (E)</Option>
							<Option value="accum_pet">PET Till Date (E)</Option>
							<Option value="accum_aet">AET Till Date (E)</Option>
							<Option value="accum_deficit">Crop Deficit Till Date (E)</Option>
							<Option value="accum_sec_runoff">Secondary Runoff Till Date (E)</Option>
							<Option value="accum_gw_rech">Groundwater Recharge Till Date (E)</Option>
						</Select>
					)}
				</Form.Item>
				{/*
				<Form.Item label= "Parameter" hasFeedback>
					{getFieldDecorator('parameter')(
						<Dropdown
							overlay={
								<Menu>
									<Menu.Item>Available Soil Moisture On Date (E)</Menu.Item>
								</Menu>
							}
						>
							<Input
								onChange={value => {
									console.log('From input :' + value);
								}}
							/>
						</Dropdown>
					)}
				</Form.Item>
				
				<Form.Item label="Parameter" hasFeedback>
					{getFieldDecorator('parameter', {
						initialValue: 'accum_sm'
					})(
						<TreeSelect
							treeData={all_params}
							onChange={null}
						/>
					)}
				</Form.Item>
				*/}
				<Form.Item label="Layer Type" hasFeedback>
					{getFieldDecorator('layer_type', {
						initialValue: 'Raster'
					})(
						<TreeSelect
							treeData={layer_types}
							onChange={null}
						/>
					)}
				</Form.Item>
				
				<Form.Item {...formSubmitLayout}>
					<Button
						type="primary"
						htmlType="submit"
					>
						Add
					</Button>
				</Form.Item>

			</Form>
		);
	}
}

const WrappedAddLayerForm = connect()(Form.create({name: 'add_layer_form'})(AddLayerForm));

export default WrappedAddLayerForm;