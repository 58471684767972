import produce from 'immer';

import * as constants from '../../constants';

const initial_state = {
	selected_region: null,
	pan_extent: null,
	region_selection_popover_visible: false,
	layers: [{
		title: 'Base-map (OpenStreetMap)',
		visible: true,
		zIndex: 0
	}, {
		title: 'Administrative Boundaries',
		visible: true,
		children: [{
			title: constants.VILLAGE_LAYER_TITLE,
			visible: false,
			zIndex: 1
		}, {
			title: constants.CLUSTER_LAYER_TITLE,
			visible: false,
			zIndex: 2
		}, {
			title: constants.TALUKA_LAYER_TITLE,
			visible: false,
			zIndex: 3
		}, {
			title: constants.DISTRICT_LAYER_TITLE,
			visible: true,
			zIndex: 4
		}]
	}],
};

const modeling = (state=initial_state, action) => produce(state, draft => {
	switch(action.type) {
		case 'TOGGLE_REGION_SELECTION_POPOVER_VISIBLE':
			draft.region_selection_popover_visible = !draft.region_selection_popover_visible;
			break;
		case 'SET_SELECTED_MODELING_REGION':
			draft.selected_region = action.payload.selectedOptions;
			break;
		case 'SET_MODELING_REGION_STATIC_DATA':
			const points = action.payload.data.points;
			let points_data_as_obj = {};
			for (let i = 0; i < points.length; i++) {
				if (points_data_as_obj.hasOwnProperty(points[i].x)) {
					points_data_as_obj[points[i].x][points[i].y] = points[i];
				} else {
					points_data_as_obj[points[i].x] = {[points[i].y]: points[i]};
				}
			}
			action.payload.data.points_data_as_obj = points_data_as_obj;
			draft.layers.push({
				identity_params: action.payload.identity_params,
				title: `${action.payload.identity_params.region_type} ${action.payload.identity_params.region_id}`,
				static_data: action.payload.data,
				visible: true,
				children: []
			});
			break;
		case 'ADD_MODEL_CONFIGURATION_LAYER_SUBGROUP':
			const layer_group = draft.layers.filter(lg => lg.title === action.payload.layer_group_title)[0];
			layer_group.children.push({
				title: ['crop', 'sowing_threshold', 'year'].map(p => `${p} ${action.payload.identity_params[p]}`).join(', '),
				identity_params: action.payload.identity_params,
				children: []
			})
			break;
		case 'SET_MODELING_LAYER_VISIBILITY':
			draft.layers[0].visible = action.payload.checkedKeys.indexOf(draft.layers[0].title) !== -1;
			draft.layers[1].children.forEach(l => {
				l.visible = action.payload.checkedKeys.indexOf(l.title) !== -1;
			});
			draft.layers.forEach((lg, lg_idx) => {
				if (lg_idx < 2) return;
				lg.children.forEach(l => {
					l.visible = action.payload.checkedKeys.indexOf(l.title) !== -1;
				});
			});
			break;
		case 'SET_MODELING_ADMIN_LAYER_VISIBILITY_DUE_TO_ZOOM':
			draft.layers[1].children.forEach((l, i) => {
				l.visible = action.payload.checked_layer_titles.indexOf(l.title) !== -1;
			})
			break;
		// case 'ADD_MODELING_REGION_LAYERS':
		// 	const region_type = ['district', 'taluka', 'cluster', 'village'][state.selected_region.length-1];
		// 	const narrowest_selected_region = state.selected_region[state.selected_region.length-1];
		// 	draft.layers.push({
		// 		region_type,
		// 		region_id: narrowest_selected_region.value,
		// 		extent: narrowest_selected_region.extent,
		// 		extent_native: narrowest_selected_region.extent_native,
		// 		poly_id: narrowest_selected_region.poly_id
		// 	});
		// 	break;
		case 'PAN_TO_MODELING_REGION_EXTENT':
			draft.pan_extent = action.payload.extent;
			break;
		case 'ADD_MODELING_REGION_LAYER_GROUP':
			draft.layers.push(action.payload);
			break;
		case 'ADD_LAYER_TO_MODELING_REGION_LAYER_GROUP':
			let group = draft.layers.filter(lg => lg.title === action.payload.layer_group_title)[0];
			if (action.payload.layer_subgroup_title) {
				group = group.children.filter(lsg => lsg.title === action.payload.layer_subgroup_title)[0];
			}
			action.payload.layer.visible = true;
			group.children.push(action.payload.layer);
			// for(const layer_group of draft.layers) {
			// 	if (layer_group.title === action.payload.layer_group_title) {
			// 		action.payload.layer.visible = true;
			// 		layer_group.children.push(action.payload.layer);
			// 		break;
			// 	}
			// }
			break;
		case 'RUN_MODEL':
			draft.layers.push({
				title: 'Model Outputs'
			});
			break;
	}
});

export default modeling;