export const HEADER_HEIGHT = 50;
export const LEFT_SIDEBAR_WIDTH = 40;
export const RIGHT_SIDEBAR_WIDTH = 310;
export const LEGEND_GRADIENT_BAR_HEIGHT = 150;

export const DATA_TABLE_PAGE_SIZE = 100;

export const PARAM_LABELS = {
  sm: 'Soil Moisture',
  pri_runoff: 'Primary Runoff',
  infil: 'Infiltration',
  aet: 'Actual Evapotranspiration',
  sec_runoff: 'Secondary Runoff',
  gw_rech: 'Groundwater Recharge',
  pet: 'Potetial Evapotranspiration',
  deficit: 'Crop Deficit',
  rain: 'Rainfall',
  accum_sm: 'Available Soil Moisture',
  accum_pri_runoff: 'Annual Primary Runoff (Till Date)',
  accum_infil: 'Annual Infiltration (Till Date)',
  accum_aet: 'Annual Actual Evapotranspiration (Till Date)',
  accum_sec_runoff: 'Annual Secondary Runoff (Till Date)',
  accum_gw_rech: 'Annual Groundwater Recharge (Till Date)',
  accum_pet: 'Annual Potetial Evapotranspiration (Till Date)',
  accum_deficit: 'Crop Deficit (Till Date)',
  accum_rain: 'Annual Rainfall (Till Date)',
  max_dry_spell_len: 'Maximum Dry-Spell',
  curr_dry_spell_len: 'Current Dry-Spell',
};

// note that order of entries for each param is important (should be in ascending order of value)
export const PARAM_LEGEND_GRADIENT_COLOUR_STOPS = {
  sm: [
    {value: -10, y_frac: 1, colour: '#ff0000', rgba: {r: 255, g: 0, b: 0, a: 1}},
    {value: 0, y_frac: 0.5, colour: '#00ff00', rgba: {r: 0, g: 255, b: 0, a: 1}},
    {value: 10, y_frac: 0, colour: '#0000ff', rgba: {r: 0, g: 0, b: 255, a: 1}},
  ],
  pri_runoff: [
    {value: 0, y_frac: 1, colour: '#ff0000', rgba: {r: 255, g: 0, b: 0, a: 1}},
    {value: 25, y_frac: 0.5, colour: '#00ff00', rgba: {r: 0, g: 255, b: 0, a: 1}},
    {value: 50, y_frac: 0, colour: '#0000ff', rgba: {r: 0, g: 0, b: 255, a: 1}},
  ],
  infil: [
    {value: 0, y_frac: 1, colour: '#ff0000', rgba: {r: 255, g: 0, b: 0, a: 1}},
    {value: 25, y_frac: 0.5, colour: '#00ff00', rgba: {r: 0, g: 255, b: 0, a: 1}},
    {value: 50, y_frac: 0, colour: '#0000ff', rgba: {r: 0, g: 0, b: 255, a: 1}},
  ],
  aet: [
    {value: 0, y_frac: 1, colour: '#ff0000', rgba: {r: 255, g: 0, b: 0, a: 1}},
    {value: 2.5, y_frac: 0.5, colour: '#00ff00', rgba: {r: 0, g: 255, b: 0, a: 1}},
    {value: 5, y_frac: 0, colour: '#0000ff', rgba: {r: 0, g: 0, b: 255, a: 1}},
  ],
  sec_runoff: [
    {value: 0, y_frac: 1, colour: '#ff0000', rgba: {r: 255, g: 0, b: 0, a: 1}},
    {value: 25, y_frac: 0.5, colour: '#00ff00', rgba: {r: 0, g: 255, b: 0, a: 1}},
    {value: 50, y_frac: 0, colour: '#0000ff', rgba: {r: 0, g: 0, b: 255, a: 1}},
  ],
  gw_rech: [
    {value: 0, y_frac: 1, colour: '#ff0000', rgba: {r: 255, g: 0, b: 0, a: 1}},
    {value: 25, y_frac: 0.5, colour: '#00ff00', rgba: {r: 0, g: 255, b: 0, a: 1}},
    {value: 50, y_frac: 0, colour: '#0000ff', rgba: {r: 0, g: 0, b: 255, a: 1}},
  ],
  pet: [
    {value: 0, y_frac: 1, colour: '#ff0000', rgba: {r: 255, g: 0, b: 0, a: 1}},
    {value: 2.5, y_frac: 0.5, colour: '#00ff00', rgba: {r: 0, g: 255, b: 0, a: 1}},
    {value: 5, y_frac: 0, colour: '#0000ff', rgba: {r: 0, g: 0, b: 255, a: 1}},
  ],
  deficit: [
    {value: 0, y_frac: 1, colour: '#ff0000', rgba: {r: 255, g: 0, b: 0, a: 1}},
    {value: 5, y_frac: 0.5, colour: '#00ff00', rgba: {r: 0, g: 255, b: 0, a: 1}},
    {value: 10, y_frac: 0, colour: '#0000ff', rgba: {r: 0, g: 0, b: 255, a: 1}},
  ],
  rain: [
    {value: 0, y_frac: 1, colour: '#ff0000', rgba: {r: 255, g: 0, b: 0, a: 1}},
    {value: 25, y_frac: 0.5, colour: '#00ff00', rgba: {r: 0, g: 255, b: 0, a: 1}},
    {value: 50, y_frac: 0, colour: '#0000ff', rgba: {r: 0, g: 0, b: 255, a: 1}},
  ],
  accum_sm: [
    {value: 0, y_frac: 1, colour: '#ff0000', rgba: {r: 255, g: 0, b: 0, a: 1}},
    {value: 50, y_frac: 0.5, colour: '#00ff00', rgba: {r: 0, g: 255, b: 0, a: 1}},
    {value: 100, y_frac: 0, colour: '#0000ff', rgba: {r: 0, g: 0, b: 255, a: 1}},
  ],
  accum_pri_runoff: [
    {value: 0, y_frac: 1, colour: '#ff0000', rgba: {r: 255, g: 0, b: 0, a: 1}},
    {value: 250, y_frac: 0.5, colour: '#00ff00', rgba: {r: 0, g: 255, b: 0, a: 1}},
    {value: 500, y_frac: 0, colour: '#0000ff', rgba: {r: 0, g: 0, b: 255, a: 1}},
  ],
  accum_infil: [
    {value: 0, y_frac: 1, colour: '#ff0000', rgba: {r: 255, g: 0, b: 0, a: 1}},
    {value: 250, y_frac: 0.5, colour: '#00ff00', rgba: {r: 0, g: 255, b: 0, a: 1}},
    {value: 500, y_frac: 0, colour: '#0000ff', rgba: {r: 0, g: 0, b: 255, a: 1}},
  ],
  accum_aet: [
    {value: 0, y_frac: 1, colour: '#ff0000', rgba: {r: 255, g: 0, b: 0, a: 1}},
    {value: 250, y_frac: 0.5, colour: '#00ff00', rgba: {r: 0, g: 255, b: 0, a: 1}},
    {value: 500, y_frac: 0, colour: '#0000ff', rgba: {r: 0, g: 0, b: 255, a: 1}},
  ],
  accum_sec_runoff: [
    {value: 0, y_frac: 1, colour: '#ff0000', rgba: {r: 255, g: 0, b: 0, a: 1}},
    {value: 250, y_frac: 0.5, colour: '#00ff00', rgba: {r: 0, g: 255, b: 0, a: 1}},
    {value: 500, y_frac: 0, colour: '#0000ff', rgba: {r: 0, g: 0, b: 255, a: 1}},
  ],
  accum_gw_rech: [
    {value: 0, y_frac: 1, colour: '#ff0000', rgba: {r: 255, g: 0, b: 0, a: 1}},
    {value: 250, y_frac: 0.5, colour: '#00ff00', rgba: {r: 0, g: 255, b: 0, a: 1}},
    {value: 500, y_frac: 0, colour: '#0000ff', rgba: {r: 0, g: 0, b: 255, a: 1}},
  ],
  accum_pet: [
    {value: 0, y_frac: 1, colour: '#ff0000', rgba: {r: 255, g: 0, b: 0, a: 1}},
    {value: 250, y_frac: 0.5, colour: '#00ff00', rgba: {r: 0, g: 255, b: 0, a: 1}},
    {value: 500, y_frac: 0, colour: '#0000ff', rgba: {r: 0, g: 0, b: 255, a: 1}},
  ],
  accum_deficit: [
    {value: 0, y_frac: 1, colour: '#ff0000', rgba: {r: 255, g: 0, b: 0, a: 1}},
    {value: 50, y_frac: 0.5, colour: '#00ff00', rgba: {r: 0, g: 255, b: 0, a: 1}},
    {value: 100, y_frac: 0, colour: '#0000ff', rgba: {r: 0, g: 0, b: 255, a: 1}},
  ],
  accum_rain: [
    {value: 0, y_frac: 1, colour: '#ff0000', rgba: {r: 255, g: 0, b: 0, a: 1}},
    {value: 250, y_frac: 0.5, colour: '#00ff00', rgba: {r: 0, g: 255, b: 0, a: 1}},
    {value: 500, y_frac: 0, colour: '#0000ff', rgba: {r: 0, g: 0, b: 255, a: 1}},
  ],
  max_dry_spell_len: [
    {value: 0, y_frac: 1, colour: '#0000ff', rgba: {r: 0, g: 0, b: 255, a: 1}},
    {value: 15, y_frac: 0.5, colour: '#00ff00', rgba: {r: 0, g: 255, b: 0, a: 1}},
    {value: 30, y_frac: 0, colour: '#ff0000', rgba: {r: 255, g: 0, b: 0, a: 1}},
  ],
  curr_dry_spell_len: [
    {value: 0, y_frac: 1, colour: '#0000ff', rgba: {r: 0, g: 0, b: 255, a: 1}},
    {value: 7, y_frac: 0.5, colour: '#00ff00', rgba: {r: 0, g: 255, b: 0, a: 1}},
    {value: 15, y_frac: 0, colour: '#ff0000', rgba: {r: 255, g: 0, b: 0, a: 1}},
  ],
}

export const PARAM_UNITS = {
  sm: 'mm',
  pri_runoff: 'mm',
  infil: 'mm',
  aet: 'mm',
  sec_runoff: 'mm',
  gw_rech: 'mm',
  pet: 'mm',
  deficit: 'mm',
  rain: 'mm',
  accum_sm: 'mm',
  accum_pri_runoff: 'mm',
  accum_infil: 'mm',
  accum_aet: 'mm',
  accum_sec_runoff: 'mm',
  accum_gw_rech: 'mm',
  accum_pet: 'mm',
  accum_deficit: 'mm',
  accum_rain: 'mm',
  max_dry_spell_len: 'days',
  curr_dry_spell_len: 'days'
}

export const VILLAGE_LAYER_TITLE = 'All Villages of PoCRA Districts'
export const CLUSTER_LAYER_TITLE = 'All Clusters of PoCRA Districts'
export const TALUKA_LAYER_TITLE = 'All Talukas of PoCRA Districts'
export const DISTRICT_LAYER_TITLE = 'PoCRA Districts'