import React, { Component } from 'react';
import { connect } from 'react-redux';

import 'ol/ol.css';

import OlMap from 'ol/Map';
import OlOverlay from 'ol/Overlay';
import { ScaleLine } from 'ol/control';
import OlView from 'ol/View';
import * as OlLayer from 'ol/layer';
import * as OlSource from 'ol/source';
import * as OlProjLib from 'ol/proj';
import * as OlCoordinate from 'ol/coordinate';
import * as OlExtentLib from 'ol/extent';

import * as constants from '../constants';

import { getMapComponentState } from '../redux/selectors';
import {
  change_identify_point_coordinates,
  set_admin_layer_visibility_due_to_zoom,
  pending_request_satisfied
} from '../redux/actions';


class MapComponent extends Component {
  
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
  }

  componentDidMount() {

    this.basic_mapCenter = OlProjLib.fromLonLat([77, 19.5]);
    this.basic_zoom = 7;
    // const mapCenter = (this.basic_mapCenter_coordinates);
    const map_popup = document.getElementById('map-popup');
    this.map_popup_closer = document.getElementById('map-popup-closer');
    this.map_popup_overlay = new OlOverlay({
      element: map_popup,
      autoPan: true,
      autoPanAnimation: {
        duration: 250
      }
    });
    this.map_popup_closer.onclick = () => this.props.change_identify_point_coordinates({
      identify_enabled: true,
      coordinates: null
    });
    
    this.base_layer = new OlLayer.Tile({
      source: new OlSource.OSM()
    });
    this.map = new OlMap({
      target: this.mapRef.current,
      layers: [this.base_layer],
      overlays: [this.map_popup_overlay],
      view: new OlView({
        center: this.basic_mapCenter,
        zoom: this.basic_zoom
      })
    });

    this.map.addControl(new ScaleLine());

    this.layer_village = new OlLayer.Image({
      title: constants.VILLAGE_LAYER_TITLE,
      source: new OlSource.ImageWMS({
        url: '/geoserver/pocra_v2/wms?service=WMS&version=1.1.0&request=GetMap&layers=pocra_v2:pocra_districts_all_villages&styles=pocra_districts_all_villages&srs=EPSG:32643&format=image%2Fpng',
        serverType: 'geoserver'
      }),
      visible: false,
      zIndex: 1
    });
    this.map.addLayer(this.layer_village);

    this.layer_cluster = new OlLayer.Image({
      title: constants.CLUSTER_LAYER_TITLE,
      source: new OlSource.ImageWMS({
        url: '/geoserver/pocra_v2/wms?service=WMS&version=1.1.0&request=GetMap&layers=pocra_v2:pocra_districts_all_clusters&styles=pocra_districts_all_clusters&srs=EPSG:32643&format=image%2Fpng',
        serverType: 'geoserver'
      }),
      visible: false,
      zIndex: 2
    });
    this.map.addLayer(this.layer_cluster);

    this.layer_taluka = new OlLayer.Image({
      title: constants.TALUKA_LAYER_TITLE,
      source: new OlSource.ImageWMS({
        url: '/geoserver/pocra_v2/wms?service=WMS&version=1.1.0&request=GetMap&layers=pocra_v2:pocra_districts_all_talukas&styles=pocra_districts_all_talukas&srs=EPSG:32643&format=image%2Fpng',
        serverType: 'geoserver'
      }),
      visible: false,
      zIndex: 3
    });
    this.map.addLayer(this.layer_taluka);

    this.layer_district = new OlLayer.Image({
      title: constants.DISTRICT_LAYER_TITLE,
      source: new OlSource.ImageWMS({
        url: '/geoserver/pocra_v2/wms?service=WMS&version=1.1.0&request=GetMap&layers=pocra_v2:pocra_districts_no_gaps&styles=pocra_districts_with_or_without_gaps&srs=EPSG:32643&format=image%2Fpng',
        serverType: 'geoserver'
      }),
      visible: true,
      zIndex: 4
    });
    this.map.addLayer(this.layer_district);

    // this.map.on('postcompose', this.map.updateSize);

    this.map.on('moveend', evt => {
      let visible_layer_titles = [];
      const zoomLevel = this.map.getView().getZoom();
      this.layer_district.setVisible(this.layer_district.getVisible() || zoomLevel <= 8);
      if (this.layer_district.getVisible()) visible_layer_titles.push(constants.DISTRICT_LAYER_TITLE);
      this.layer_taluka.setVisible(zoomLevel > 8);
      if (this.layer_taluka.getVisible()) visible_layer_titles.push(constants.TALUKA_LAYER_TITLE);
      this.layer_cluster.setVisible(zoomLevel > 10);
      if (this.layer_cluster.getVisible()) visible_layer_titles.push(constants.CLUSTER_LAYER_TITLE);
      this.layer_village.setVisible(zoomLevel > 12);
      if (this.layer_village.getVisible()) visible_layer_titles.push(constants.VILLAGE_LAYER_TITLE);
      this.props.set_admin_layer_visibility_due_to_zoom(visible_layer_titles);
    });

    this.parameter_layers = [];

    this.map.on('click', evt => this.props.change_identify_point_coordinates({
      identify_enabled: this.props.map_identify_point.enabled,
      coordinates: OlProjLib.toLonLat(evt.coordinate)
    }));

    this.map.on('change:size', evt => this.map.updateSize);

    this.mapRef.current.setAttribute('style',
      'height: '+(window.innerHeight - constants.HEADER_HEIGHT).toString()+'px; ' +
      'width: '+(window.innerWidth - constants.LEFT_SIDEBAR_WIDTH - constants.RIGHT_SIDEBAR_WIDTH).toString()+'px;'
    );
    
    this.map.updateSize();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.map_identify_point.coordinates !== this.props.map_identify_point.coordinates) {
      this.map_popup_overlay.setPosition(
        this.props.map_identify_point.coordinates ? 
        OlProjLib.fromLonLat(this.props.map_identify_point.coordinates) :
        null
      );
    }
    if (prevProps.map_pan_region_extent !== this.props.map_pan_region_extent) {
      this.map.getView().fit(
        OlProjLib.transformExtent(
          OlExtentLib.boundingExtent([
            this.props.map_pan_region_extent.slice(0,2), this.props.map_pan_region_extent.slice(2,4)
          ]), 'EPSG:4326', 'EPSG:3857'
        ), {
          size: this.map.getSize(),
          duration: 2000
        }
      );
    }
    console.log(prevProps.map_add_layer.layers[2].children.length < this.props.map_add_layer.layers[2].children.length);
    if (prevProps.map_add_layer.layers[2].children.length !== this.props.map_add_layer.layers[2].children.length) {
      const prev_layers = prevProps.map_add_layer.layers[2].children;
      const layers = this.props.map_add_layer.layers[2].children;
      if (prev_layers.length < layers.length) {
        console.log(prev_layers, layers);
        // if (prev_layers[2].children.length < layers[2].children.length) {
        layers.forEach((layer, i) => {
          console.log('From MapComponent.componentDidUpdate all layer number: ', i)
          if (layer.pending_request_id === null) {
            return;
          }
          const { title, url, zIndex, pending_request_id } = layer;
          // console.log('from MapComponent.componentDidUpdate\'s new layer title, url and zIndex : ', title, url, zIndex);
          
          // the following is to prevent the bug that only that part of the layer-image loads
          // which covers the current view.
          // So, if the current view does not cover the full extent of the pocra districts,
          // only respective partial image loads. The rest of the image is never requested
          // even when the view zooms out to cover the full extent of pocra districts.
          const current_view = this.map.getView();
          this.map.setView(new OlView({
            center: this.basic_mapCenter,
            zoom: this.basic_zoom
          }));

          const new_layer_source = new OlSource.ImageWMS({
            url: url,
            params: layer.params,
            // url: '/geoserver/pocra_v2/wms?service=WMS&version=1.1.0&request=GetMap&layers=pocra_v2%3Apocra_districts_all_talukas&bbox=456935.71875%2C1951177.375%2C939952.3125%2C2408355.5&width=768&height=726&srs=EPSG%3A32643&format=image/png&styles=style_for_36',
            // params: (layer.sld_body) ? {sld_body: layer.sld_body} : {},
            serverType: 'geoserver',
            // The following function is based on (not necessarily confident) inferences from research on the web
            // imageLoadFunction: (image, src) => {
            //   let img = image.getImage();
            //   let canvas = document.createElement('canvas');
            //   [canvas.width, canvas.height] = this.map.getSize();
            //   canvas.getContext('2d').drawImage(layer.image);
            // }
          });
          
          new_layer_source.on('imageloadend', evt => {
            this.props.pending_request_satisfied(pending_request_id);
            // set the view back to current view
            window.setTimeout(() => this.map.setView(current_view), 1000);
            
          })
          
          const new_layer = new OlLayer.Image({
            title: title,
            source: new_layer_source,
            zIndex: zIndex
          });
          this.map.addLayer(new_layer);
          this.parameter_layers.push(new_layer);

        });
        console.log('from MapComponent.componentDidUpdate\'s parameter_layers : ', this.parameter_layers);
      }
    // console.log('from MapComponent.componentDidUpdate : ', this.props.map_add_layer.layers);
      if (prev_layers.length > layers.length) {
        const keys_remaining_in_param_layers = layers.map(l => l.title);
        let new_parameter_layers = [];
        console.log(keys_remaining_in_param_layers);
        console.log(this.parameter_layers);
        for (let l of this.parameter_layers) {
          console.log(l);
          if (keys_remaining_in_param_layers.includes(l.get('title'))) {
            new_parameter_layers.push(l);
          } else {
            this.map.removeLayer(l);
          }
        }
        this.parameter_layers = new_parameter_layers;
      }
    }

    this.base_layer.setVisible(this.props.map_add_layer.layers[0].visible);
    this.layer_village.setVisible(this.props.map_add_layer.layers[1].children[0].visible);
    this.layer_cluster.setVisible(this.props.map_add_layer.layers[1].children[1].visible);
    this.layer_taluka.setVisible(this.props.map_add_layer.layers[1].children[2].visible);
    // console.log('from MapComponent componentDidUpdate : layer_taluka.visible = ', this.layer_taluka.getVisible());
    this.layer_district.setVisible(this.props.map_add_layer.layers[1].children[3].visible);
    this.parameter_layers.forEach((l, i) => {
      // console.log('layer number: ', i);
      l.setVisible(this.props.map_add_layer.layers[2].children[i].visible);
    });

    this.mapRef.current.setAttribute('style',
      'height: '+(window.innerHeight - constants.HEADER_HEIGHT).toString()+'px; ' +
      'width: '+(window.innerWidth - constants.LEFT_SIDEBAR_WIDTH - constants.RIGHT_SIDEBAR_WIDTH).toString()+'px;'
    );
    this.map.updateSize();
  }

  render() {
    const popup = (
      <div id="map-popup">
        <button id="map-popup-closer">X</button>
        <div id="map-popup-content">
          <p>Coordinates :</p>
          <code>{OlCoordinate.toStringHDMS(this.props.map_identify_point.coordinates)}</code>
        </div>
      </div>
    );
    return (
      <React.Fragment>
        <div className="map" ref={this.mapRef}></div>
        {popup}
      </React.Fragment>
    );
  }
}

const mapStateToProps = getMapComponentState;
const mapDispatchToProps = {
  change_identify_point_coordinates,
  set_admin_layer_visibility_due_to_zoom,
  pending_request_satisfied
};

export default connect(mapStateToProps, mapDispatchToProps)(MapComponent);