import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	Popover, Dropdown, Menu, Tooltip, Icon, Cascader,
	Tabs, Tree, Divider, Button, Table, InputNumber,
	Row, Col,
} from 'antd';

import { Stage, Layer, Rect, Text } from 'react-konva';
import { ChromePicker } from 'react-color';

import * as constants from '../constants';

import {
	getMapsPageRightSideBarState,
	getChangeStyleWidgetState
} from '../redux/selectors';
import {
	set_visibility,
	add_to_selected_param_layers,
	// remove_selected_layers
	remove_all_layers,
	remove_layer,
	change_layer_style,
} from '../redux/actions';


var topmost_layer_of_layers = layers => (
	layers.length > 0 ?	[...layers].filter(l => l.visible).sort((l1, l2) => (l2.zIndex - l1.zIndex))[0] : null
);


class LayerToolsWidget extends Component {

	constructor(props) {
		super(props);

		this.state = this.props.colour_stops ? {
			colour_stops: JSON.parse(JSON.stringify(this.props.colour_stops)),
		} : {};
	}

	render() {
		
		return (
		<React.Fragment>
			<Button ghost type="danger" onClick={e => this.props.remove_layer(this.props.layer_title)}>
				Remove Layer
			</Button>
			{this.state.colour_stops ? (
			<React.Fragment>
			<Divider />
			<p style={{fontWeight: 'bold'}}>Change Colour Style</p>
			<div style={{width: 300, minHeight: 200, maxHeight: 200, overflowY: 'scroll'}}>
			{this.state.colour_stops.map((cs, cs_idx) => (
				<Row type="flex" align="middle" justify="space-around" style={{height: 50}} key={cs_idx.toString()}>
					<Col span={6}>
						<InputNumber
							value={cs.value}
							onChange={value => {
								let new_colour_stops = [...this.state.colour_stops];
								new_colour_stops[cs_idx].value = value;
								this.setState({
									colour_stops: new_colour_stops,
								});
							}}
						/>
					</Col>
					<Col span={6}>
						<Popover
							placement="bottom"
			      			content={
			      				<ChromePicker
			      					color={cs.colour}
									onChangeComplete={(colour, event) => {
										console.log(colour);
										let new_colour_stops = [...this.state.colour_stops];
										new_colour_stops[cs_idx].colour = colour.hex;
										new_colour_stops[cs_idx].rgba = colour.rgb;
										this.setState({
											colour_stops: new_colour_stops,
										});
									}}
								/>
							}
		      			>
			      			<div style={{
		      					width: '100%', height: 16,
		      					background: 'rgba(' + cs.rgba.r+','+cs.rgba.g+','+cs.rgba.b+','+cs.rgba.a + ')'
		      				}}/>
			      		</Popover>
					</Col>
				</Row>
				)
			)}
				<div style={{textAlign: 'center', marginTop: 10}}>
					<Button
						style={{marginRight: 10}}
						onClick={e => this.setState({
							colour_stops: JSON.parse(JSON.stringify(this.props.colour_stops)) /*using JSON to achieve the required deep-copy*/
						})}
					>
						Reset
					</Button>
					<Button
						type="primary"
						style={{marginLeft: 10}}
						onClick={e => this.props.change_layer_style(this.state)}
					>
						Apply
					</Button>
				</div>
			</div>
			</React.Fragment>
			) : null}
		</React.Fragment>
		);
	}
}

class ChangeStyleWidget extends Component {

	constructor(props) {
		super(props);

		this.state = {values: {}, colours: {}, props_edited: true};
		for (const {value, colour} of props.colour_stops) {
			const key = value.toString();
			this.state.values[key] = value;
			this.state.colours[key] = colour;
		}
	// 	// for (const {key, value, colour} of this.dataSource) {
	// 	// 	this.state.values[key] = value;
	// 	// 	this.state.colours[key] = colour;
	// 	// }
	}

	static getDerivedStateFromProps(props, state) {
		console.log(state);
		// if (props.new_props) 
		if (!state.props_edited) {
			return {...state, props_edited: true};
		} else {
			let new_state = {values: {}, colours: {}, props_edited: true};
			for (const {value, colour} of props.colour_stops) {
				const key = value.toString();
				new_state.values[key] = value;
				new_state.colours[key] = colour;
			}
			console.log(new_state);
			return new_state;
		}
	}

	render() {
		// return null;
		// const colour_stops =
		// 	topmost_layer_of_layers(this.props.param_layers).legend_gradient_colour_stops;
		const dataSource = this.props.colour_stops.sort((cs1, cs2) => (cs1.value - cs2.value)).map((cs, idx) => (
			{key: cs.value.toString(), value: cs.value, colour: cs.colour}
		));

		console.log(this.props);

		const columns = [{
			title: 'Value',
			dataIndex: 'value',
			width: '75%',
			render: (text, record) => {
				// console.log(text, record);
				return (
					<InputNumber
						style={{width: 100}}
						value={this.state.values[record.key]}
						onChange={value => {
							this.setState({
								values: {...this.state.values, [record.key]: value},
								props_edited: false
							});
							// console.log(this.state);
						}}
					/>
				);
			}
		}, {
			title: 'Colour',
			dataIndex: 'colour',
			width: '25%',
			render: (text, record) => (
				<Popover
					placement="bottom"
	      			content={
	      				<ChromePicker
	      					color={this.state.colours[record.key]}
							onChangeComplete={(colour, event) => {
								this.setState({
									colours: {...this.state.colours, [record.key]: colour.hex},
									props_edited: false
								});
								console.log(record.colour);
							}}
						/>
					}
	      			trigger="click"
      			>
	      			<div style={{
      					width: 16, height: 16,
      					background: this.state.colours[record.key] || text
      				}}/>
	      		</Popover>
			)
		}];
		// console.log(this.props);
		
		return (
			<div>
				<Table
					dataSource={dataSource}
					columns={columns}
					size="small"
					scroll={{x: 200, y: 200}}
					pagination={false}
				/>
				<div style={{textAlign: 'center'}}>
					<Button onClick={e => this.setState({values:{}, colours:{}})}>Reset</Button>
					<Button onClick={e => this.props.change_layer_style(this.state)}>Done</Button>
				</div>
			</div>
		);
	}
}

const mapStateToCSWProps = getChangeStyleWidgetState;
const mapDispatchToCSWProps = {
	change_layer_style
};

const WrappedChangeStyleWidget = connect(mapStateToCSWProps, mapDispatchToCSWProps)(ChangeStyleWidget);


class MapsPageRightSideBar extends Component {

	build_layers_subtree(data_obj) {

		return Object.entries(data_obj).map(([k, v]) => {
			if (typeof v === 'object') {
				return (
					<Tree.TreeNode
						title={
							<span style={{fontSize: '1.1em', fontWeight: 'bold'}}>{ k }</span>
						}
						key={k.replace(/[\W]+/g, '')}
					>
						{this.build_point_data_subtree(v)}
					</Tree.TreeNode>
				);
			} else {
				return (
					<Tree.TreeNode
						title={
							<span style={{fontWeight: 'bold'}}>
								{ k } : <span style={{fontWeight: 'normal'}}>{ v }</span>
							</span>
						}
						key={k.replace(/[\W]+/g, '')}
					/>
				);
			}
		});
	}

	build_point_data_subtree(data_obj) {
		return Object.entries(data_obj).map(([k, v]) => {
			if (typeof v === 'object') {
				return (
					<Tree.TreeNode
						title={
							<span style={{fontSize: '1.1em', fontWeight: 'bold'}}>{ k }</span>
						}
						key={k.replace(/[\W]+/g, '')}
					>
						{this.build_point_data_subtree(v)}
					</Tree.TreeNode>
				);
			} else {
				return (
					<Tree.TreeNode
						title={
							<span style={{fontWeight: 'bold'}}>
								{ k } : <span style={{fontWeight: 'normal'}}>{ v }</span>
							</span>
						}
						key={k.replace(/[\W]+/g, '')}
					/>
				);
			}
		});
	}

	render() {
		const {
			map_identify_point, map_add_layer,
			set_visibility, add_to_selected_param_layers,
			// remove_selected_layers
			remove_all_layers, remove_layer,
			change_layer_style
		} = this.props;
		console.log(map_add_layer);
		const { layers } = map_add_layer;
		const layer_tree_checkedKeys = [];
		if (layers[0].visible) layer_tree_checkedKeys.push(layers[0].title);
		layers[1].children.forEach(l => {
			if (l.visible) layer_tree_checkedKeys.push(l.title);
		});
		layers[2].children.forEach(l => {
			if (l.visible) layer_tree_checkedKeys.push(l.title);
		});

		/*	Following is the title prop for map_add_layer.layers[2] TreeNode.
			Not used anymore, since selection-based actions don't seem to work properly.
			(Surprisingly, clicking a menu item in a hover-triggered dropdown associated with a TreeNode also selects(!) that TreeNode.
			Also, add_to_selected_param_layers gets dispatched automatically (no idea why) immediately after remove_selected_layer is dispatched)
			Also, differentiating between 'selected' and 'checked' status
			may not be obvious to (at least lay-) users.

			<React.Fragment>
				<span style={{fontWeight: 'bold'}}>{ layers[2].title }</span>
				<Dropdown overlay={
					<Menu>
						<Menu.Item
							key="remove"
							onClick={remove_selected_layers}
						>
							Remove Selected
						</Menu.Item>
					</Menu>
				}>
					<span style={{paddingLeft: 10, color: 'blue'}}>
						<Icon type="tool" />
					</span>
				</Dropdown>
			</React.Fragment>

			
			Also not using following props for layers_tree Tree anymore

			multiple
			onSelect={add_to_selected_param_layers}
			selectedKeys={map_add_layer.selectedKeys}
		*/

		const layers_tree = (
			<Tree
				blockNode
				checkable
				onCheck={set_visibility}
				defaultCheckedKeys={[layers[0].title, layers[1].children[3].title]}
				checkedKeys={layer_tree_checkedKeys}
				defaultExpandedKeys={[layers[1].title]}
				style={{minHeight: 300, maxHeight: 300, overflow: 'scroll', borderBottom: 'solid 1px #ccc'}}
			>
				<Tree.TreeNode title={layers[0].title} key={layers[0].title} />
				<Tree.TreeNode
					title={<span style={{fontWeight: 'bold'}}>{ layers[1].title }</span>}
					key={layers[1].title}
				>
					<Tree.TreeNode title={layers[1].children[0].title} key={layers[1].children[0].title} />
					<Tree.TreeNode title={layers[1].children[1].title} key={layers[1].children[1].title} />
					<Tree.TreeNode title={layers[1].children[2].title} key={layers[1].children[2].title} />
					<Tree.TreeNode title={layers[1].children[3].title} key={layers[1].children[3].title} />
				</Tree.TreeNode>
				<Tree.TreeNode
					title={
						<React.Fragment>
							<Dropdown overlay={
								<Menu>
									<Menu.Item
										key="remove"
										onClick={e => remove_all_layers()}
									>
										Remove All Layers in Group
									</Menu.Item>
								</Menu>
							}>
								<span style={{paddingRight: 10, color: 'blue'}}>
									<Icon type="tool" />
								</span>
							</Dropdown>
							<span style={{fontWeight: 'bold'}}>{ layers[2].title }</span>
						</React.Fragment>
					}
					key={layers[2].title}
				>
					{[...layers[2].children].sort((l1, l2) => (l2.zIndex - l1.zIndex)).map(
						l => (
							<Tree.TreeNode
								title={
									<React.Fragment>
										<Popover
											content={
												<LayerToolsWidget
													colour_stops={l.legend_gradient_colour_stops}
													layer_title={l.title}
													remove_layer={remove_layer}
													change_layer_style={change_layer_style}
												/>
											}
											placement="left"
											mouseEnterDelay={0.5}
										>
											<span style={{paddingRight: 10, color: 'blue'}}>
												<Icon type="tool" />
											</span>
										</Popover>
										<span>{ l.title }</span>
									</React.Fragment>
								}
								key={l.title}
							/>
						)
					)}
				</Tree.TreeNode>
			</Tree>
		);
		const point_data_tree = map_identify_point.point_data ? (
			<Tree
				blockNode
				style={{minHeight: 300, maxHeight: 300, overflow: 'scroll', borderBottom: 'solid 1px #ccc'}}
			>
				{this.build_point_data_subtree(map_identify_point.point_data)}
			</Tree>
		) : <div style={{minHeight: 300}} />;

		const topmost_visible_layer_of_group_2 = topmost_layer_of_layers(layers[2].children);
		
		return (
			<React.Fragment>
			<Tabs
				defaultActiveKey="Layers"
				forceRender={true}
			>
				<Tabs.TabPane tab="Layers" key="Layers" style={{maxHeight: '100%'}}>
					{layers_tree}
				</Tabs.TabPane>
				<Tabs.TabPane tab="Points" key="Points" style={{maxHeight: '100%'}}>
					{point_data_tree}
				</Tabs.TabPane>
			</Tabs>
			{(topmost_visible_layer_of_group_2 && topmost_visible_layer_of_group_2.legend_gradient_colour_stops) ? (
			<div>
				<div style={{paddingLeft: 20}}>
	    			<Stage width={200} height={200}>
						<Layer>
							<Rect
								x={10}
								y={10}
								width={20}
								height={constants.LEGEND_GRADIENT_BAR_HEIGHT}
								fillLinearGradientStartPointY={0}
								fillLinearGradientEndPointY={constants.LEGEND_GRADIENT_BAR_HEIGHT}
								fillLinearGradientColorStops={[].concat(...(
									topmost_visible_layer_of_group_2.legend_gradient_colour_stops
									.map(cs => [cs.y_frac, cs.colour])
								))}
							/>
							<Text x={5} y={180} text={"Units : "+topmost_visible_layer_of_group_2.measurement_unit} />
							{topmost_visible_layer_of_group_2.legend_gradient_colour_stops.map(cs => {
								// console.log(cs);
								return (
									<Text
										key={cs.value.toString()}
										x={ 35 }
										y={ 10 + Math.round(constants.LEGEND_GRADIENT_BAR_HEIGHT * cs.y_frac) - 5}
										text={cs.value.toString()}
									/>
								);
							})}
						</Layer>
					</Stage>
		      	</div>
		      	{/*
		      	<div>
		      		<Popover
		      			placement="topRight"
		      			title="Colour Gradient"
		      			content={
		      				<WrappedChangeStyleWidget
		      					colour_stops={topmost_visible_layer_of_group_2.legend_gradient_colour_stops}
		      				/>
		      			}
		      			trigger="click"
	      			>
		      			<Button type="link">Change Style</Button>
		      		</Popover>
		      	</div>
		      */}
		    </div>
	      	) : null}
			</React.Fragment>
		);
	}
}

const mapStateToProps = getMapsPageRightSideBarState;
const mapDispatchToProps = {
	set_visibility,
	add_to_selected_param_layers,
	// remove_selected_layers
	remove_all_layers,
	remove_layer,
	change_layer_style,
};

export default connect(mapStateToProps, mapDispatchToProps)(MapsPageRightSideBar);