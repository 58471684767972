import React, { Component } from 'react';
import { connect } from 'react-redux';

import 'ol/ol.css';

import OlMap from 'ol/Map';
import OlOverlay from 'ol/Overlay';
import { ScaleLine } from 'ol/control';
import OlView from 'ol/View';
import * as OlLayer from 'ol/layer';
import * as OlSource from 'ol/source';
import * as OlProjLib from 'ol/proj';
import * as OlCoordinate from 'ol/coordinate';
import * as OlExtentLib from 'ol/extent';

import * as constants from '../constants';

import * as model from '../model';

import { getModelingMapComponentState } from '../redux/selectors';
import {
  set_modeling_admin_layer_visibility_due_to_zoom
} from '../redux/actions';


// console.log(model);

// function prepare_and_run_model(pixels) {
//   const pixel = [0, 0, 0, pixels[0][3]];
//   for (let i = 0; i < pixels.length; i++) {
//     for (let j = 0; j < 3; j++) {
//       pixel[j] += pixels[i][j];
//     }
//   }
//   return pixel;
// }

class ModelingMapComponent extends Component {
  
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.basic_uSP_params = {
      service: 'WMS',
      version: '1.1.0',
      request: 'GetMap',
      srs: 'EPSG:32643',
      format: 'image/png'
    };
    this.model_data = {};
    this.layer_groups = [];
  }

  componentDidMount() {

    const mapCenter = OlProjLib.fromLonLat([77, 19.5]);
    // const map_popup = document.getElementById('modeling-map-popup');
    // this.map_popup_closer = document.getElementById('modeling-map-popup-closer');
    // this.map_popup_overlay = new OlOverlay({
    //   element: map_popup,
    //   autoPan: true,
    //   autoPanAnimation: {
    //     duration: 250
    //   }
    // });
    // this.map_popup_closer.onclick = () => this.props.change_identify_point_coordinates({
    //   identify_enabled: true,
    //   coordinates: null
    // });
    
    this.base_layer = new OlLayer.Tile({
      source: new OlSource.OSM()
    });

    this.map = new OlMap({
      target: this.mapRef.current,
      layers: [this.base_layer],
      // overlays: [this.map_popup_overlay],
      view: new OlView({
        center: mapCenter,
        zoom: 7
      })
    });

    this.map.addControl(new ScaleLine());

    this.layer_village = new OlLayer.Image({
      title: constants.VILLAGE_LAYER_TITLE,
      source: new OlSource.ImageWMS({
        url: '/geoserver/pocra/wms?service=WMS&version=1.1.0&request=GetMap&layers=pocra:pocra_districts_all_villages&srs=EPSG:32643&format=image%2Fpng',
        serverType: 'geoserver'
      }),
      zIndex: 1
    });
    this.map.addLayer(this.layer_village);

    this.layer_cluster = new OlLayer.Image({
      title: constants.CLUSTER_LAYER_TITLE,
      source: new OlSource.ImageWMS({
        url: '/geoserver/pocra/wms?service=WMS&version=1.1.0&request=GetMap&layers=pocra:pocra_districts_all_clusters&srs=EPSG:32643&format=image%2Fpng',
        serverType: 'geoserver'
      }),
      zIndex: 2
    });
    this.map.addLayer(this.layer_cluster);

    this.layer_taluka = new OlLayer.Image({
      title: constants.TALUKA_LAYER_TITLE,
      source: new OlSource.ImageWMS({
        url: '/geoserver/pocra/wms?service=WMS&version=1.1.0&request=GetMap&layers=pocra:pocra_districts_all_talukas&srs=EPSG:32643&format=image%2Fpng',
        serverType: 'geoserver'
      }),
      zIndex: 3
    });
    this.map.addLayer(this.layer_taluka);

    this.layer_district = new OlLayer.Image({
      title: constants.DISTRICT_LAYER_TITLE,
      source: new OlSource.ImageWMS({
        url: '/geoserver/pocra/wms?service=WMS&version=1.1.0&request=GetMap&layers=pocra:pocra_districts&srs=EPSG:32643&format=image%2Fpng',
        serverType: 'geoserver'
      }),
      zIndex: 4
    });
    this.map.addLayer(this.layer_district);

    // this.map.on('postcompose', this.map.updateSize);

    this.map.on('moveend', evt => {
      let visible_layer_titles = [];
      const zoomLevel = this.map.getView().getZoom();
      this.layer_district.setVisible(this.layer_district.getVisible() || zoomLevel <= 8);
      if (this.layer_district.getVisible()) visible_layer_titles.push(constants.DISTRICT_LAYER_TITLE);
      this.layer_taluka.setVisible(zoomLevel > 8);
      if (this.layer_taluka.getVisible()) visible_layer_titles.push(constants.TALUKA_LAYER_TITLE);
      this.layer_cluster.setVisible(zoomLevel > 10);
      if (this.layer_cluster.getVisible()) visible_layer_titles.push(constants.CLUSTER_LAYER_TITLE);
      this.layer_village.setVisible(zoomLevel > 12);
      if (this.layer_village.getVisible()) visible_layer_titles.push(constants.VILLAGE_LAYER_TITLE);
      
      this.props.set_modeling_admin_layer_visibility_due_to_zoom(visible_layer_titles);

    });

    this.map.addLayer(
      new OlLayer.Image({
        title: 'title',
        source: new OlSource.ImageWMS({
          url: '/geoserver/pocra/wms?service=WMS&version=1.1.0&request=GetMap&srs=EPSG%3A32643&format=image%2Fpng&layers=pocra%3Apd_soil_polygon_texture_id&styles=pocra%3Asoil_texture_raster&bbox=579650%2C2062750%2C582350%2C2065350&width=27&height=26&env=poly_id%3A13305',
          serverType: 'geoserver'
        }),
        zIndex: 1
      })
    );
    // console.log(this.map.getLayers());

    // this.map.addControl(new ScaleLine());

    this.parameter_layers = [];
    this.parameter_sources = [];

    // this.map.on('change:size', evt => this.map.updateSize);
    
    // this.map.on('click', (evt) => this.props.change_identify_point_coordinates({
    //   identify_enabled: this.props.map_identify_point.enabled,
    //   coordinates: OlProjLib.toLonLat(evt.coordinate)
    // }));
    this.map.on('moveend', evt => console.log(this.model_data));
    this.mapRef.current.setAttribute('style',
      'height: '+(window.innerHeight - constants.HEADER_HEIGHT).toString()+'px; ' +
      'width: '+(window.innerWidth - constants.LEFT_SIDEBAR_WIDTH - constants.RIGHT_SIDEBAR_WIDTH).toString()+'px;'
    );
    
    this.map.updateSize();
    console.log(this.layer_groups);
  }

  get_new_layer(layer) {
    console.log('get_new_layer with params : ', layer);
    const { title, url, params } = layer;
    // new_layer_source.on('imageloadend', evt => this.props.pending_request_satisfied(pending_request_id));
    const new_layer = new OlLayer.Image({
      title: title,
      source: new OlSource.ImageWMS({
        url,
        params,
        serverType: 'geoserver',
      }),
      zIndex: 10
    });
    return new_layer;
  }

  componentDidUpdate(prevProps) {
    // if (prevProps.map_identify_point.coordinates !== this.props.map_identify_point.coordinates) {
    //   this.map_popup_overlay.setPosition(
    //     this.props.map_identify_point.coordinates ? 
    //     OlProjLib.fromLonLat(this.props.map_identify_point.coordinates) :
    //     null
    //   );
    // }
    console.log(this.layer_groups);

    if (prevProps.modeling.pan_extent !== this.props.modeling.pan_extent) {
      const new_pan_extent = this.props.modeling.pan_extent;
      this.map.getView().fit(
        OlProjLib.transformExtent(
          OlExtentLib.boundingExtent([
            new_pan_extent.slice(0,2), new_pan_extent.slice(2,4)
          ]), 'EPSG:4326', 'EPSG:3857'
        ), {
          size: this.map.getSize(),
          duration: 2000
        }
      );
    }

    for (let i = 2; i < this.props.modeling.layers.length; i++) {
      console.log(this.layer_groups);
      if (this.layer_groups.length < (i-2)+1) {
        this.layer_groups.push({
          title: this.props.modeling.layers[i].title,
          children: []
        });
        console.log('Pushed layer_group', i);
      }
      for (let j = 0; j < this.props.modeling.layers[i].children.length; j++) {
        console.log('Looking for children of layer_group', i, this.props.modeling.layers[i].children);
        if (this.layer_groups[i-2].children.length < j+1) {
          if (this.props.modeling.layers[i].children[j].hasOwnProperty('children')) {
            this.layer_groups[i-2].children.push({
              title: this.props.modeling.layers[i].children[j].title,
              children: []
            });
            console.log('Pushed layer_group', i, j);
            console.log(this.layer_groups);
          } else {
            console.log('found new layer at ', i, j);
            const new_layer = this.get_new_layer(this.props.modeling.layers[i].children[j]);
            this.map.addLayer(new_layer);
            this.layer_groups[i-2].children.push(new_layer);
            console.log('Pushed layer', i, j);
            console.log(this.layer_groups[i-2].children.length);
          }
        }
        if (this.props.modeling.layers[i].children[j].hasOwnProperty('children')) {
          console.log(i, j);
          for (let k = this.layer_groups[i-2].children[j].children.length; k < this.props.modeling.layers[i].children[j].children.length; k++) {
            console.log('found new layer at ', i, j, k);
            const new_layer = this.get_new_layer(this.props.modeling.layers[i].children[j].children[k]);
            this.layer_groups[i-2].children[j].children.push(new_layer);
            console.log(this.layer_groups);
          }
        }
      }
      // if (this.layer_groups[i-2].length < this.props.modeling.layers[i].children.length) {
      //   for (let j = this.layer_groups[i-2].length; j < this.props.modeling.layers[i].children.length; j++) {
      //     console.log('found new child at ', j);
      //     const layer = this.props.modeling.layers[i].children[j];
      //     // console.log(layer);
      //     const { title, url, params } = layer;
      //     const new_layer_source = new OlSource.ImageWMS({
      //       url,
      //       params,
      //       serverType: 'geoserver',
      //     });
      //     // new_layer_source.on('imageloadend', evt => this.props.pending_request_satisfied(pending_request_id));
      //     const new_layer = new OlLayer.Image({
      //       title: title,
      //       source: new_layer_source,
      //       zIndex: 10
      //     });  
      //     this.map.addLayer(new_layer);
      //     this.layer_groups[i-2].push(new_layer);
      //   }
      // }
    }

    // if (prevProps.modeling.layers.length < this.props.modeling.layers.length) {
    //   const layer = this.props.modeling.layers[this.props.modeling.layers.length - 1];
    //   console.log(layer);
    //   const { title, url, params } = layer;
    //   const new_layer_source = new OlSource.ImageWMS({
    //     url,
    //     params,
    //     serverType: 'geoserver',
    //   });
    //   // new_layer_source.on('imageloadend', evt => this.props.pending_request_satisfied(pending_request_id));
    //   const new_layer = new OlLayer.Image({
    //     title: title,
    //     source: new_layer_source,
    //     zIndex: 10
    //   });  
    //   this.map.addLayer(new_layer);
    //   this.layer_groups.push([new_layer]);
      // const new_mapCenter = OlProjLib.fromLonLat([76.3898, 19.3306]);
      // this.map.setView(new OlView({
      //   center: new_mapCenter,
      //   zoom: 14
      // }));
      // if (true) {
        // const layer_specs = layers[layers.length - 1];
        // const input_layers = [
        //   'pd_soil_polygon_texture_id', 'pd_soil_polygon_depth_category_id',
        //   'pd_lulc_polygon_special_type_id', 'pd_'+layer_specs.region_type+'_id'
        // ];
        // const input_styles = [
        //   'soil_texture_raster', 'soil_depth_raster',
        //   'lulc_special_type_raster', 'mark_selected_gid_raster'
        // ];
        // this.map.getView().fit(
        //   OlProjLib.transformExtent(
        //     OlExtentLib.boundingExtent([
        //       layer_specs.extent.slice(0,2), layer_specs.extent.slice(2,4)
        //     ]), 'EPSG:4326', 'EPSG:3857'
        //   ), {
        //     size: this.map.getSize(),
        //     duration: 2000
        //   }
        // );
        // input_layers.forEach((l, i) => {
        //   let [minx, miny, maxx, maxy] = layer_specs.extent_native;
        //   minx = Math.floor(minx/100)*100 - 50;
        //   miny = Math.floor(miny/100)*100 - 50;
        //   maxx = Math.ceil(maxx/100)*100 + 50;
        //   maxy = Math.ceil(maxy/100)*100 + 50;
        //   const uSP = new URLSearchParams({
        //     ...this.basic_uSP_params,
        //     layers: 'pocra:' + l,
        //     styles: 'pocra:' + input_styles[i],
        //     env: 'poly_id:' + layer_specs.poly_id
        //   });
        //  console.log('/geoserver/pocra/wms?' + uSP.toString());
          // const new_layer = new OlLayer.Image({
          //   title: layer_specs.region_type + ' ' + layer_specs.value,
          //   source: new OlSource.Raster({
          //     sources: [
          //       new OlSource.ImageWMS({
          //         url: '/geoserver/pocra/wms?' + uSP.toString(),
          //         serverType: 'geoserver'
          //       })
          //     ],
          //     operation: (pixels, data) => {
          //       return [pixels[0][0]+0, pixels[0][1]+0, pixels[0][2]+0, pixels[0][3]];
          //     }
          //   }),
          // // new OlSource.ImageWMS({
          // //     url: '/geoserver/pocra/wms?' + uSP.toString(),
          // //     serverType: 'geoserver'
          // //   }),
          //   zIndex: 1
          // });



          // if (layer.title === 'Model Outputs') {
          //   console.log('URL : ', this.props.modeling.layers[0].url);
          //   const new_layer = new OlLayer.Image({
          //     title: 'Model output',
          //     source: new OlSource.Raster({
          //       sources: this.parameter_layers.map(l => l.getSource()),
          //       operation: model.prepare_and_run_model,
          //       lib: model.lib
          //     }),
          //     zIndex: 2
          //   });
          //   this.map.addLayer(new_layer);
          //   this.parameter_layers.forEach(l => l.setVisible(false));
          //   this.parameter_layers.push(new_layer);
          //   console.log(this.parameter_layers);
          // } else {
          //   const raster_source = new OlSource.Raster({
          //     sources: [new OlSource.ImageWMS({
          //       url: layer.url,
          //       serverType: 'geoserver'
          //     })],
          //     operation: model.input_layer_operation_image_form,
          //     operationType: 'image'
          //   });
          //   raster_source.on('beforeoperations', evt => { evt.data.title = layer.title });
          //   raster_source.on('afteroperations', evt => {
          //     // const count = evt.data.imageData.data.reduce((counter, curr_val) => {
          //       // if (curr_val != 0) {
          //         // return counter + 1;
          //       // } else {
          //       //   return counter;
          //       // }
          //     // }, 0);
          //     // console.log(evt.data.title, count);
          //     this.model_data[evt.data.title] = evt.data.imageData;
          //   });
          //   const new_layer = new OlLayer.Image({
          //     title: layer.title,
          //     source: raster_source,
          //     zIndex: 1
          //   });
          //   this.map.addLayer(new_layer);
          //   this.parameter_layers.push(new_layer);
          //   this.parameter_sources.push(raster_source);
          //   console.log(this.parameter_layers);
          // }
      // }
    // }

    this.base_layer.setVisible(this.props.modeling.layers[0].visible);
    this.layer_village.setVisible(this.props.modeling.layers[1].children[0].visible);
    this.layer_cluster.setVisible(this.props.modeling.layers[1].children[1].visible);
    this.layer_taluka.setVisible(this.props.modeling.layers[1].children[2].visible);
    this.layer_district.setVisible(this.props.modeling.layers[1].children[3].visible);
    console.log(this.layer_groups);
    this.layer_groups.forEach((lg, lg_idx) => {
      lg.children.forEach((lsg, lsg_idx) => {
        console.log(lg_idx, lsg_idx);
        console.log(this.layer_groups.length, this.layer_groups[lg_idx].children.length);
        if (!this.props.modeling.layers[2+lg_idx].children[lsg_idx].hasOwnProperty('children')) {
          lsg.setVisible(this.props.modeling.layers[2+lg_idx].children[lsg_idx].visible);
        } else {
          lsg.children.forEach((l, l_idx) => {
            console.log(l);
            console.log(lg_idx, lsg_idx, l_idx);
            l.setVisible(this.props.modeling.layers[2+lg_idx].children[lsg_idx].children[l_idx].visible);
          });
        }
      });
    });

    this.mapRef.current.setAttribute('style',
      'height: '+(window.innerHeight - constants.HEADER_HEIGHT).toString()+'px; ' +
      'width: '+(window.innerWidth - constants.LEFT_SIDEBAR_WIDTH - constants.RIGHT_SIDEBAR_WIDTH).toString()+'px;'
    );
    this.map.updateSize();
  }

  render() {
    // const popup = (
    //   <div id="map-popup">
    //     <button id="map-popup-closer">X</button>
    //     <div id="map-popup-content">
    //       <p>Coordinates :</p>
    //       <code>{OlCoordinate.toStringHDMS(this.props.map_identify_point.coordinates)}</code>
    //     </div>
    //   </div>
    // );
    return (
      // <React.Fragment>
        <div className="map" ref={this.mapRef}></div>
      // </React.Fragment>
    );
  }
}

const mapStateToProps = getModelingMapComponentState;
const mapDispatchToProps = { 
  // change_identify_point_coordinates,
  set_modeling_admin_layer_visibility_due_to_zoom
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelingMapComponent);